import { createChatBotMessage }     from 'react-chatbot-kit';

import AssistantHeader              from '../components/AssistantHeader/AssistantHeader';
import AssistantAvatar              from '../components/AssistantAvatar/AssistantAvatar';
import UserAvatar                   from '../components/UserAvatar/UserAvatar';

import SearchAnswer                 from '../answers/SearchAnswer/SearchAnswerFlipCardFromTuxi';
import ClockAnswer                  from '../answers/ClockAnswer/ClockAnswer';
import TimerAnswer                  from '../answers/TimerAnswer/TimerAnswer';
import CalendarAnswer               from '../answers/CalendarAnswer/CalendarAnswer';
import CalculatorAnswer             from '../answers/CalculatorAnswer/CalculatorAnswer';
import LocationAnswer               from '../answers/LocationAnswer/LocationAnswer';
import IPAddressAnswer              from '../answers/IPAddressAnswer/IPAddressAnswer';
import PlayPacmanAnswer             from '../answers/PlayPacmanAnswer/PlayPacmanAnswer';
import PlaySnakeAnswer              from '../answers/PlaySnakeAnswer/PlaySnakeAnswer';
import NewsAnswer                   from '../answers/NewsAnswer/NewsAnswer';
import DealsAnswer                  from '../answers/DealsAnswer/DealsAnswer';
import VideoAnswer                  from '../answers/VideoAnswer/VideoAnswer';
import FingerAnswer                 from '../answers/FingerAnswer/FingerAnswer';
import ParrotBoxAnswer              from '../answers/ParrotBoxAnswer/ParrotBoxAnswer';
import FlipAnswer                   from '../answers/FlipAnswer/FlipAnswer';
import CarouselAnswer               from '../answers/CarouselCardAnswer/CarouselCardAnswer';
import ClickToFlipAnswer            from '../answers/ClickToFlipAnswer/ClickToFlipAnswer';
import JokeAndFunRiddleAnswer       from '../answers/JokeAndFunRiddleAnswer/JokeAndFunRiddleAnswer';
import WeatherForecastAnswer        from '../answers/WeatherForecastAnswer/WeatherForecastAnswer';
import QRAnswer                     from '../answers/QRAnswer/QRAnswer';
import LyricsAnswer                 from '../answers/LyricsAnswer/LyricsAnswer';
import FAQAnswer                    from '../answers/FAQAnswer/FAQAnswer';
import PlayWordleAnswer             from '../answers/PlayWordleAnswer/PlayWordleAnswer';
import CardAnswer                   from '../answers/CardAnswer/CardAnswer';

const botName = 'Ginger';

const AssistantConfig = {
    headerText        : botName,
    placeholderText   : "Ask your question here",
    state: {
        assistantName : botName,
        loggedIn      : false,
        user          : null,
    },
    initialMessages: [ 
        createChatBotMessage(
            `Hi, I'm ${botName} and I'm here to help. What can I do for you?`, 
            { withAvatar: true }
        ),
    ],
    customComponents: {
        botAvatar:                    (props) => <AssistantAvatar { ...props } icon={'0'} />,
        userAvatar:                   (props) => <UserAvatar      { ...props } icon={'0'} />,
        header:                       (props) => <AssistantHeader { ...props } state={AssistantConfig.state} />,
    },
    customMessages: {
        searchAnswer:                 (props) => <SearchAnswer                  { ...props } />,
        clockAnswer:                  (props) => <ClockAnswer                   { ...props } />,
        timerAnswer:                  (props) => <TimerAnswer                   { ...props } />,
        calendarAnswer:               (props) => <CalendarAnswer                { ...props } />,
        calculatorAnswer:             (props) => <CalculatorAnswer              { ...props } />,
        weatherAnswer:                (props) => <WeatherForecastAnswer         { ...props } />,
        locationAnswer:               (props) => <LocationAnswer                { ...props } />,
        ipAddressAnswer:              (props) => <IPAddressAnswer               { ...props } />,
        jokeAnswer:                   (props) => <JokeAndFunRiddleAnswer        { ...props } />,
        funRiddleAnswer:              (props) => <JokeAndFunRiddleAnswer        { ...props } />,
        playPacmanAnswer:             (props) => <PlayPacmanAnswer              { ...props } />,
        playSnakeAnswer:              (props) => <PlaySnakeAnswer               { ...props } />,
        playWordleAnswer:             (props) => <PlayWordleAnswer              { ...props } />,
        newsAnswer:                   (props) => <NewsAnswer                    { ...props } />,
        dealsAnswer:                  (props) => <DealsAnswer                   { ...props } />,
        videoAnswer:                  (props) => <VideoAnswer                   { ...props } />,
        fingerAnswer:                 (props) => <FingerAnswer                  { ...props } />,
        parrotBoxAnswer:              (props) => <ParrotBoxAnswer               { ...props } />,
        flipAnswer:                   (props) => <FlipAnswer                    { ...props } />,
        carouselAnswer:               (props) => <CarouselAnswer                { ...props } />,
        clickToFlipAnswer:            (props) => <ClickToFlipAnswer             { ...props } />,
        weatherForecastAnswer:        (props) => <WeatherForecastAnswer         { ...props } />,
        qrAnswer:                     (props) => <QRAnswer                      { ...props } />,
        lyricsAnswer:                 (props) => <LyricsAnswer                  { ...props } />,
        faqAnswer:                    (props) => <FAQAnswer                     { ...props } />,
        cardAnswer:                   (props) => <CardAnswer                    { ...props } />,
    },
};

export default AssistantConfig;