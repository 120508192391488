// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/ds_digital/ds-digi.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: 'DigitalClock';\n    src: local('DigitalClock'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n\n.Display {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    background: #111;\n    height: 20%;\n    color: var(--green-200);\n    font: normal 2.5rem 'DotMatrix';\n    padding: 12px;\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n}", "",{"version":3,"sources":["webpack://./src/components/Calculator/Display.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,sFAAsF;AAC1F;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;IACX,uBAAuB;IACvB,+BAA+B;IAC/B,aAAa;IACb,2BAA2B;IAC3B,4BAA4B;AAChC","sourcesContent":["@font-face {\n    font-family: 'DigitalClock';\n    src: local('DigitalClock'), url(../../fonts/ds_digital/ds-digi.ttf) format('truetype');\n}\n\n.Display {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    background: #111;\n    height: 20%;\n    color: var(--green-200);\n    font: normal 2.5rem 'DotMatrix';\n    padding: 12px;\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
