import React, { useEffect, useState } from 'react';

import './ClockAnswer.css';

const ClockAnswer = (props) => {
    const [value, setValue] = useState(new Date());

    useEffect(
        () => {
            const interval = setInterval(
                () => setValue(new Date()), 1000
            );
  
            return () => { clearInterval(interval); }
        }, []
    );

    var localeTimeString = value.toLocaleTimeString();
    var t = localeTimeString.split(" ")[0];
    var i = localeTimeString.split(" ")[1];
    var tp = t.split(":");

    var weekday = new Array(7);
    weekday[0] = "Sun";
    weekday[1] = "Mon";
    weekday[2] = "Tue";
    weekday[3] = "Wed";
    weekday[4] = "Thu";
    weekday[5] = "Fri";
    weekday[6] = "Sat";

    var dayString = weekday[value.getDay()];

    var dateString = (value.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + 
        '/' + value.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});

    return (
        <div className='clock-answer div-table'>
            <div className='div-row'>
                <div className='div-column' style={{'float': 'left'}}>
                    <div className='div-row clock-answer-clock-day'>{dayString}</div>
                    <div className='div-row clock-answer-clock-date'>{dateString}</div>
                </div>
                <div className='div-column' style={{'float': 'right'}}>
                    <div className='div-column clock-answer-clock clock-answer-clock-hr-min'>{tp[0]}:{tp[1]}</div>
                    <div className='div-column clock-answer-clock clock-answer-clock-sec'>:{tp[2]}</div>
                    <div className='div-column clock-answer-clock clock-answer-clock-am-pm'>{i === 'PM' ? 'P' : 'A'}</div>
                </div>
            </div>
        </div>
    );
}

export default ClockAnswer;
