import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../lib/auth-context";

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';

const Authenticated = ({ children }) => {
    const { loggedOut } = useAuth();
    const location = useLocation();

    return loggedOut ? 
        <Navigate to={`/${homePage}/login`} replace state={{ targetPath: location.pathname }} /> : 
        children
};

export default Authenticated;