import parse from "html-react-parser";

import './ContentForDeals.css';

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';
const FEED     = `/${homePage}/data/dealsapi/deals.json`;
const SOURCE   = 'Slickdeals';
const BYLINE   = 'Popular Deals';
const HERO_IMG = `/${homePage}/images/deals-hero-image.jpg`;

const AGENTS = {
    'Find Deals' : { handle: '@deals', config: require('../../agents/app-deals.json') },
}

const template = (item) => {
    if (!item.title) return <div className='text'>{ parse(item.message) }</div>

    let thumbnailURL = item.image;

    if (thumbnailURL === 'PLACEHOLDER') thumbnailURL = HERO_IMG;

    return (
        <div className='deal'>
            <div className='source'   >{SOURCE}</div>
            <div className='title'    ><a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a></div>
            <div className="thumbnail"><img src={thumbnailURL} /></div>
            <div className="published-date">{new Date().toLocaleString()}</div>
            <div>
                <span className="abstract"><a href={item.url} target="_blank" rel="noopener noreferrer">Discussion thread</a></span>
                <span className='byline'  >&nbsp;({item.rating})</span>
                <span className="divider" >/</span>
                <span className="byline"  >{BYLINE}</span>
            </div>
        </div>
    );
};

const getSource = (agentConfig) => {
    return {
        picture : `/${homePage}/images/avatar-agents/${agentConfig.app.avatar}`,
        name    : agentConfig.app.name,
        handle  : agentConfig.app.handle,
    };
};

const getAnswer = async (feed, query) => {
    return await fetch(feed, { headers: { 'Content-Type' : 'application/json', 'Accept' : 'application/json' }})
        .then(response => response.json());
};

const getTitle = (answer) => {
    switch (answer.length) {
        case undefined: 
        case 0:  return 'No deals found';
        case 1:  return '1 deal';
        default: return `${answer.length} deals`
    }
};

const ContentForDeals = async (props) => {
    const { query, intentDisplayName } = props.state;

    const agentConfig = AGENTS[intentDisplayName].config;
    const source      = getSource(agentConfig);
    const feed        = `/${homePage}/data/dealsapi/${agentConfig.app.feed}`;
    const answer      = await getAnswer(feed, query);
    const title       = getTitle(answer.popular_deals);
    const message     = `Hello!`;

    if (answer.popular_deals[answer.popular_deals.length - 1].title === 'END') answer.popular_deals.pop();

    return {
        source   : source,
        title    : title,
        template : template,
        data     : answer.popular_deals || [{ message: message }]
    };
};

export default ContentForDeals;