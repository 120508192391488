import React from 'react';
import Timestamp from '../../components/Timestamp/Timestamp';
import Snake from 'react-simple-snake';

import './PlaySnakeAnswer.css';

const PlaySnakeAnswer = (props) => {
    return(
        <div>
            <div className='answer'>
                <div className='snake-game-board'>
                    <Snake percentageWidth='80' snakeColor='red' appleColor='white' />
                </div>
            </div>
            <Timestamp />
        </div>
    );
}

export default PlaySnakeAnswer;