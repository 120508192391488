import React, { useRef, useLayoutEffect, useState } from 'react';

import "./FlipCard.css";

const FlipCard = (props) => {
    const [ content,   setContent ]   = useState();
    const [ maxHeight, setMaxHeight ] = useState();

    const cardInnerRef = useRef();
    const cardFrontRef = useRef();
    const cardBackRef  = useRef();

    useLayoutEffect(() => {
        if (cardInnerRef.current) {
            const maxHeight = Math.max(cardFrontRef.current.offsetHeight, cardBackRef.current.offsetHeight)
            setMaxHeight({ height: maxHeight + 'px' });
        }
    }, [cardInnerRef.current, cardFrontRef.current, cardBackRef.current]);

    if (!content) {
        const { ...others } = props;
        setContent(others);
    }

    if (content === undefined) {
        return(<div key={Date.now()}>{"..."}</div>);
    }

    const markup = <div className="flip-card" style={maxHeight}>
        <div className="flip-card-inner" ref={cardInnerRef}>
            <div className="flip-card-front" ref={cardFrontRef} style={maxHeight}>
                <div className="content"  key={content.id + '-front'}>{content.front}</div>
            </div>
            <div className="flip-card-back" ref={cardBackRef} style={maxHeight}>
                <div className="content"  key={content.id + '-back'}>{content.back}</div>
            </div>
        </div>
    </div>;

    return markup;
};

export default FlipCard;