import { useState } from 'react';
import Timestamp from '../../components/Timestamp/Timestamp';

const ParrotBoxAnswer = (props) => {
    const [ answer, setAnswer ] = useState();

    if (!answer) setAnswer({ ...props.state });

    if (answer) {
        return (
            <div>
                <div className='answer'>
                    <div><strong>Handler:</strong> {answer.handler}</div>
                    <div><strong>Utterance:</strong> {answer.utterance}</div>
                    <div><strong>Type: </strong> {answer.userMessage.messageType}</div>
                    <div><strong>Keyword: </strong> {answer.userMessage.keyword}</div>
                    <div><strong>Request:</strong> {answer.userMessage.utterance || answer.userMessage.command || answer.userMessage.directive}</div>
                    <div><strong>Source:</strong> {answer.source}</div>
                </div>
                <Timestamp />
            </div>
        );
    }
};

export default ParrotBoxAnswer;