import React from 'react';

import Timestamp from '../../components/Timestamp/Timestamp';
import Video     from '../../components/Video/VideoByYouTube';

import './VideoAnswer.css';

const VideoAnswer = (props) => {
    const query = props.state.query || 'kitten';

    props.scrollIntoView();

    return(
        <div>
            <div className='video-answer-card'><Video query={query} /></div>
            <Timestamp />
        </div>
    );
}

export default VideoAnswer;
