import { useState } from 'react';
import useSWR from 'swr';
import Timestamp from '../../components/Timestamp/Timestamp';

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';
const defaultAvatarThumbnail = `/${homePage}/images/avatar-system/generic-oval-512.png`;

const fetcher = (url) => fetch(url).then((response) => response.json());

const FingerAnswer = (props) => {
    const [ answer, setAnswer ] = useState();
    const { data, error } = useSWR(process.env.REACT_APP_USERS_API_FINGER_URL, fetcher);
 
    if (error) {
        console.log('>>> Error: ', error);
        return "Error fetching data...";
    }
 
    if (!data && !answer) return "Loading data...";
    if (data && !answer) setAnswer(data);
    if (!answer) return "Loading data...";

    const markup = answer.map(
        (item) => {
            return (
                <div className='data-item' key={item._id}>
                    <img className = 'avatar-thumbnail' 
                        src        = {item.picture || defaultAvatarThumbnail} 
                        alt        = 'avatar thumbnail' 
                        onError    = {({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src     = defaultAvatarThumbnail;
                        }}
                    />
                    <span><strong>{item.name}</strong></span>&nbsp;<span>&lt;{item.email}&gt;</span>
                </div>
            )
        ;}
    );

    props.scrollIntoView();

    return (
        <div>
            <div className='answer finger'>{markup}</div>
            <Timestamp />
        </div>
    );
};

export default FingerAnswer;