import React from 'react';
import Timestamp from '../../components/Timestamp/Timestamp';
import { Calendar } from 'primereact/calendar';

import './CalendarAnswer.css';

const CalendarAnswer = (props) => {
    return(
        <div>
            <div className='calendar-answer'>
                <Calendar inline showOnFocus={false} value={new Date()} disabledDays={[0,6]}></Calendar>
            </div>
            <Timestamp />
        </div>
    );
}

export default CalendarAnswer;