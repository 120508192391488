import React, { useState } from 'react';
import Timestamp from '../../components/Timestamp/Timestamp';

import './IPAddressAnswer.css';
import '../WeatherAnswer/weather-icons.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function GetIPAddress() {
    const [ ipAddressInfo, setIPAddressInfo ] = useState(null);

    if (!ipAddressInfo) {
        fetch("https://httpbin.org/ip")
        .then(
            (response) => { return response.text() }
        )
        .then(
            (data) => {
                setIPAddressInfo(JSON.parse(data).origin);
            }
        );
    }

    return ipAddressInfo;
}

function GetNetworkRecord(ipAddress) {
    const [ networkRecord, setNetworkRecord ] = useState(null);

    if (ipAddress && !networkRecord) {
        fetch(`${process.env.REACT_APP_HOOKS_URL}/${process.env.REACT_APP_ENDPOINT_GET_IP_INFO}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            },
            body: `ip=${ipAddress}`
        })
        .then(
            (response) => { 
                return response.json();
            }
        )
        .then(
            (data) => {
                setNetworkRecord(data);
            }
        )
        .catch(
            (error) => {
                console.log(error);
            }
        );
    }

    return networkRecord;
}

const IPAddressAnswer = (props) => {
    const ipAddress = GetIPAddress();
    const networkRecord = GetNetworkRecord(ipAddress);

    if (networkRecord) {
        return (
            <div>
                <div className='ipaddress-answer'>
                    <div className='ipaddress-answer-title'>{ipAddress}</div>
                    <div>
                        <div className='ipaddress-line'><FontAwesomeIcon icon="network-wired" className='ipaddress-icons' />&nbsp;{networkRecord["org"]}</div>
                        <div className='ipaddress-line'>{networkRecord["city"]}, {networkRecord["region"]} {networkRecord["postal"]}</div>
                        <div className='ipaddress-line'>{networkRecord["country_name"]}</div>
                        <div className='ipaddress-geolocation-line'><FontAwesomeIcon icon="map-marker" className='ipaddress-icons' />&nbsp;{networkRecord["latitude"]}, {networkRecord["longitude"]}</div>
                    </div>
                </div>
                <Timestamp />
            </div>
        );
    }

    return(
        <div>
            <div className='ipaddress-answer'>
                <div>Retrieving network information...</div>
            </div>
            <Timestamp />
        </div>
    );
}

export default IPAddressAnswer;