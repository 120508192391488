export async function dialogFlowResponder(props) {
    let agentResponse = await fetch(process.env.REACT_APP_AGENT_API_TEXT_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: new URLSearchParams({
            'message': props.utterance,
            'sessionPath': props.sessionPath
        })
    })
    .then((response) => { return response.json(); });

    console.log('>>> Agent response: ', agentResponse);

    return { 
        sessionPath:       agentResponse.sessionPath,
        data:              agentResponse.data[0], 
        responseText:      agentResponse.data[0].queryResult.fulfillmentText,
        intentDisplayName: agentResponse.data[0].queryResult.intent.displayName,
        parameters:        agentResponse.data[0].queryResult.parameters
    };
}
