import React, { useRef, useLayoutEffect, useState } from 'react';

import "./ClickToFlipCard.css";

const ClickToFlipCard = (props) => {
    const [ isFlipped , setFlipped ] = useState(false);
    const [ maxHeight, setMaxHeight ] = useState();

    const cardInnerRef = useRef();
    const cardFrontRef = useRef();
    const cardBackRef  = useRef();

    useLayoutEffect(() => {
        if (cardInnerRef.current) {
            const maxHeight = Math.max(cardFrontRef.current.offsetHeight, cardBackRef.current.offsetHeight)
            setMaxHeight({ height: maxHeight + 'px' });
        }
    }, [cardInnerRef.current, cardFrontRef.current, cardBackRef.current]);

    const handleClick = () => {
        setFlipped(!isFlipped);
    };

    const markup = (
        <div className="ctf-scene scene--card">
            <div className={ isFlipped ? "ctf-card is-flipped" : "ctf-card" } onClick={handleClick} ref={cardInnerRef} style={maxHeight}>
                <div className="ctf-card__face ctf-card__face--front" key={props.id + '-front'} ref={cardFrontRef} style={maxHeight}>{props.frontFace}</div>
                <div className="ctf-card__face ctf-card__face--back"  key={props.id + '-back'}  ref={cardBackRef}  style={maxHeight}>{props.backFace}</div>
            </div>
        </div>
    );

    return markup;
};

export default ClickToFlipCard;