import { Messages } from "../lib/messages";

const COMMAND_HANDLERS = {
    '/ctf'    : 'clickToFlipAnswer',
    '/lyrics' : 'lyricsAnswer',
    '/qr'     : 'qrAnswer',
    '/wfc'    : 'weatherForecastAnswer',
    '/x'      : 'cardAnswer',
};

class MessageParserWithDialogFlow {
    constructor(actionProvider, state) {
        this.actionProvider = actionProvider;
        this.state = state;
    }
    
    parse(message) {
        const userMessage = Messages.create(message);

        if (userMessage.isCommand() && COMMAND_HANDLERS[userMessage.keyword]) {
            userMessage.handler = COMMAND_HANDLERS[userMessage.keyword];
        }

        if (userMessage.isCommand() && userMessage.keyword === '/finger') {
            if (this.state.user?.email) {
                if (
                    this.state.user.email    === 'ginger.hot.tea@gmail.com'
                    || this.state.user.email === 'kyai.nagasasra@gmail.com'
                    || this.state.user.email === 'dalang.bengkok@gmail.com'
                ) {
                    userMessage.handler = 'fingerAnswer';
                }
            }
        }

        const props = {
            userMessage : userMessage,
            handler     : userMessage.handler,
            utterance   : userMessage.utterance,
            query       : userMessage.query(),
        };

        if (userMessage.isPlain()) {
            this.actionProvider.respondWith({ sessionPath : this.state.sessionPath, ...props });
        }
        else if (userMessage.isDirective()) {
            this.actionProvider.directMessageWith({ sessionPath : this.state.sessionPath, ...props });
        }
        else {
            this.actionProvider.answerWith(props);
        }
    }
}

export default MessageParserWithDialogFlow;