import './App.css';

import { AuthProvider } from './lib/auth-context'
import { AssistantConfigProvider } from './lib/assistant-config-context';

import { useLocation, Outlet } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab     } from '@fortawesome/free-brands-svg-icons';
import { faBroadcastTower, faCheckSquare, faCrosshairs, faFlag, faMapMarker, faNetworkWired } from '@fortawesome/free-solid-svg-icons';

import HomePage       from './content/page/HomePage';
import NavigationMenu from './content/menu/NavigationMenu';

library.add(fab, faCheckSquare, faCrosshairs, faBroadcastTower, faNetworkWired, faMapMarker, faFlag);

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';

export default function App() {
    const location = useLocation();

    return (
        <AuthProvider><AssistantConfigProvider><div className="App">
            <header className="App-header">
                <h1 className="App-title"><img className='logo' src={`/${homePage}/images/ginger-t-logo/ginger-t-logo.png`} />Ginger-T</h1>
                <NavigationMenu />
            </header>
            { location.pathname === '/' ? <HomePage /> : <Outlet /> }
            <p />
            <footer>
                <p>Found a problem, or want to suggest a feature?&nbsp;<a href="https://github.com/ginger-hot-tea/ginger-t-issues/issues" target="_blank" rel="noreferrer">Report issues</a></p>
                <p>&copy; 2022 Ginger T &middot; <a href='https://ginger-t.link/ginger-t/'>https://ginger-t.link/ginger-t/</a></p>
                <p className='app-version'>{process.env.REACT_APP_NAME} version {process.env.REACT_APP_VERSION}</p>
            </footer>
        </div></AssistantConfigProvider></AuthProvider>
    );
};