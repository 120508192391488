import './ContentForUSGovOfficials.css';

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';
const avatar   = `/${homePage}/images/avatar-agents/agent-usa-04.jpg`;

const AFFILIATION = {
    'Democratic Party' : '(D)',
    'Republican Party' : '(R)',
    'Nonpartisan'      : '(N)',
};

const CHANNEL_LOGO = {
    'Twitter'  : { 
        logo   : `/${homePage}/images/logo-channels/twitter-1.png`,
        prefix : 'https://twitter.com',
    },
    'Facebook' : { 
        logo   : `/${homePage}/images/logo-channels/facebook.png`,
        prefix : 'https://www.facebook.com',
    },
    'YouTube'  : { 
        logo   : `/${homePage}/images/logo-channels/youtube.png`,
        prefix : 'https://www.youtube.com/user',
    },
};

const ContentForUSGovOfficials = async (props) => {
    const { query } = props;

    if (!query.fields.address.stringValue) return 'Need an address to search...';

    const url = `${process.env.REACT_APP_CORS_PROXY_URL}https://www.googleapis.com/civicinfo/v2/representatives?key=AIzaSyBvXUORS6tgI08vHYpFg-JAhM1IyOJQiqQ&address=${query.fields.address.stringValue}`;

    const combinedData = [];

    const apiData = await fetch(url).then(response => response.json());

    apiData['offices'].reduce(
        (previous, current) => {
            combinedData.push({ 
                ...current, 
                officials       : apiData['officials'], 
                normalizedInput : apiData['normalizedInput']
            });
        },
        combinedData
    );

    const template = (item) => {
        const officials = []

        item.officialIndices.reduce(
            (previous, current, index) => {
                let address = null;

                if (item.officials[current].address) {
                    address = (
                        <div className="address">
                            <div className="street">{ item.officials[current].address[0].line1 }</div>
                            { item.officials[current].address[0].line2 ? <div>{item.officials[current].address[0].line2}</div> : ''}
                            <div className="city-state-zip">{ item.officials[current].address[0].city }, { item.officials[current].address[0].state } { item.officials[current].address[0].zip }</div>
                        </div>
                    );
                }

                let channels = [];

                if (item.officials[current].channels) {
                    item.officials[current].channels.reduce(
                        (cp, cc, ci) => {
                            channels.push(
                                <li className='channel' key={ci}>
                                    <span>
                                        <img className='channel-logo-thumbnail' src={ CHANNEL_LOGO[cc.type].logo } /> @
                                        <a target='_blank' href={ `${CHANNEL_LOGO[cc.type].prefix}/${cc.id}` }>{cc.id}</a>
                                    </span>
                                </li>
                            );
                        },
                        channels
                    );
                }

                const searchURL = `https://www.google.com/search?q=${encodeURIComponent(item.officials[current].name + ' ' + item.name)}`;

                officials.push(
                    <li key={index} className='official'>
                        <div>
                            <span className="full-name"><a target='_blank' href={searchURL}>{ item.officials[current].name }</a></span>
                            <span className="party">{ AFFILIATION[item.officials[current].party] || '' }</span>
                        </div>
                        { address || '' }
                        { channels.length > 0 ? <ul className='channels'>{channels}</ul> : ''}
                        <div className="phone">{ item.officials[current].phones[0] }</div>
                    </li>
                );
            },
            officials
        );

        return (
            <div className='contact-info'>
                <div className='office'>{ item.name }</div>
                <div className='officials'><ul>{ officials }</ul></div>
            </div>
        );
    };

    const source = {
        picture  : avatar,
        name     : 'US Civic Info',
        handle   : '@usa',
    };

    return { 
        data     : combinedData, 
        template : template, 
        source   : source, 
        title    : `Elected officials in ${apiData['normalizedInput'].city || ''} ${apiData['normalizedInput'].state || ''}` 
    };
};

export default ContentForUSGovOfficials;