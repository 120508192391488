// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/ds_digital/ds-digi.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: 'DigitalClock';\n    src: local('DigitalClock'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n\n.calculator-answer {\n    padding: 24px;\n    font-size: 0.85rem;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    margin-bottom: 16px;\n    background-image: url(\"https://ginger-t.link/ginger-t/images/weather-background-02.jpg\");\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n.calculator {\n    box-shadow: none;\n    margin: auto;\n}\n\n.calculator-display {\n    color: var(--green-200);\n    font-family: 'DotMatrix';\n}", "",{"version":3,"sources":["webpack://./src/answers/CalculatorAnswer/CalculatorAnswer.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,sFAAsF;AAC1F;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,wFAAwF;IACxF,2BAA2B;IAC3B,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,wBAAwB;AAC5B","sourcesContent":["@font-face {\n    font-family: 'DigitalClock';\n    src: local('DigitalClock'), url(../../fonts/ds_digital/ds-digi.ttf) format('truetype');\n}\n\n.calculator-answer {\n    padding: 24px;\n    font-size: 0.85rem;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    margin-bottom: 16px;\n    background-image: url(\"https://ginger-t.link/ginger-t/images/weather-background-02.jpg\");\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n.calculator {\n    box-shadow: none;\n    margin: auto;\n}\n\n.calculator-display {\n    color: var(--green-200);\n    font-family: 'DotMatrix';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
