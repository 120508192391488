import React, { useState } from 'react';
import useSWR from 'swr';
import { decodeHtml } from '../../lib/utilities';
import Timestamp from '../../components/Timestamp/Timestamp';
import ClickToFlipCard from '../../components/ClickToFlipCard/ClickToFlipCard';

import './JokeAndFunRiddleAnswer.css';

const API_URL = {
    "Tell a Joke":   "https://ginger-t.link/get-fun/joke",
    "Tell a Riddle": "https://ginger-t.link/get-fun/riddle",
}

const fetcher = (url) => fetch(url).then((response) => response.json());

const JokeAndFunRiddleAnswer = (props) => {
    const cardInfo = { id: Date.now() };

    let apiURL = '';

    const [ joke, setJoke ] = useState();
    const { data, error }   = useSWR(API_URL[props.state.intentDisplayName], fetcher);

    if (error) {
        console.log('>>> Error: ', error);
        return "Error fetching data...";
    }
 
    if (!data && !joke) return "Loading data...";
    if (data && !joke) setJoke(data);
    if (!joke) return "Loading data...";

    if (joke) {
        cardInfo.frontFace = decodeHtml(joke.riddle);
        cardInfo.backFace  = decodeHtml(joke.answer);

        return (
            <div>
                <div className="jokeandfunriddle-answer"><ClickToFlipCard { ...cardInfo } /></div>
                <Timestamp />
            </div>
        );
    }
};

export default JokeAndFunRiddleAnswer;