import { createContext, useContext } from "react";
import DefaultAssistantConfig from "../assistant/AssistantConfig";
import UserAvatar from '../components/UserAvatar/UserAvatar';

const AssistantConfigContext = createContext(false);

export const AssistantConfigProvider = (props) => {
    const assistantConfig = (user) => {
        const config = { ...DefaultAssistantConfig };

        if (user && user.email) {
            config.state.user = user;
            config.state.loggedIn = true;
            config.state.headerText = 'My Personal Assistant';
            config.customComponents.userAvatar = (props) => <UserAvatar { ...props} icon={'4'} picture={user.picture} />;
        }
        else {
            config.state.user = null;
            config.state.loggedIn = false;
            config.state.headerText = 'My Assistant';
            config.customComponents.userAvatar = (props) => <UserAvatar { ...props} icon={'0'} />;
        }

        return config;
    };

    return (
        <AssistantConfigContext.Provider value={{assistantConfig}} { ...props } />
    );
}

export const useAssistantConfig = () => useContext(AssistantConfigContext);