import React, { useState } from 'react';
import { usePosition } from 'use-position';
import useSWR from 'swr';

import { Carousel } from 'primereact/carousel';
import { Slider   } from 'primereact/slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 
import Timestamp from '../../components/Timestamp/Timestamp';

import '../WeatherAnswer/WeatherAnswer.css';
import './WeatherForecastAnswer.css';
import './weather-icons.min.css';

const API_BASE_URL = {
    getCurrentWeatherURL: `${process.env.REACT_APP_HOOKS_URL}/${process.env.REACT_APP_ENDPOINT_GET_CURRENT_WEATHER_INFO}`,
    getDailyForecastURL:  `${process.env.REACT_APP_HOOKS_URL}/${process.env.REACT_APP_ENDPOINT_GET_DAILY_FORECAST_INFO}`,
}

const WEEKDAYS = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];

const forecastTemplate = (daily) => {
    if (daily.id === 0) {
        const item         = daily.forecast;
        const tempData     = item.main;
        const skyCondition = item.weather[0]["description"].charAt(0).toUpperCase() + item.weather[0]["description"].slice(1);
        const sunrise      = new Date(item.sys.sunrise * 1000).toLocaleTimeString();
        const sunset       = new Date(item.sys.sunset * 1000).toLocaleTimeString();
        const hours        = new Date().getHours()
        const dayTag       = (hours < 6 || hours > 15) ? 'night' : 'day';
        const iconId       = `wi wi-owm-${dayTag}-${item.weather[0].id}`;

        let d = new Date();
        const titleString  = `${WEEKDAYS[d.getDay()]}, ${('0' + d.getDate()).slice(-2)}`;
        const titleTime    = `${d.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'})}`;

        return (
            <div key={daily.id}>
                <div className='weatherforecast-title'>{daily.location}</div>
                <div className='weatherforecast-subtitle'>{titleString}&nbsp;&nbsp;<i className="wi wi-time-4">&nbsp;</i>{titleTime}</div>
                <div className='weather-line'>
                    <i className="wi wi-thermometer"></i> {tempData["temp_max"]}&deg;F high &nbsp;
                    <i className="wi wi-thermometer"></i> {tempData["temp_min"]}&deg;F low &nbsp;
                    <i className="wi wi-humidity"></i> {tempData["humidity"]}% humidity
                </div>
                <div className='weather-line'>
                    <div>
                        <div className='weather-line'>Feels like&nbsp; <i className="wi wi-thermometer"></i> {tempData["feels_like"]}&deg;F</div>
                        <div className='weather-temp'>{Math.round(tempData["temp"])}&deg;F</div>
                    </div>
                    <div className='weather-icon'><i className={iconId}></i></div>
                </div>
                <div className='weather-line'><div className='weather-condition'>{skyCondition}</div></div>
                <div className='weather-line'>
                    <div>Rise&nbsp;<i className="wi wi-horizon-alt"></i> {sunrise}&nbsp;&bull;&nbsp;Set&nbsp;<i className="wi wi-sunset"></i> {sunset}</div>
                </div>
                <div className='weather-line'>
                    <div className='weather-lat-long'><FontAwesomeIcon icon="map-marker" className='ipaddress-icons' />&nbsp;{item.coord.lat}, {item.coord.lon}</div>
                </div>
            </div>
        );
    }

    const rows      = { '00': {}, '03': {}, '06': {}, '09': {}, '12': {}, '15': {}, '18': {}, '21': {} };
    const tempRange = { min: 500, max: -500 };

    daily.forecast.map((item) => {
        if (item.main.temp_min < tempRange.min) { tempRange.min = item.main.temp_min }
        if (item.main.temp_max > tempRange.max) { tempRange.max = item.main.temp_max }
    });

    daily.forecast.map((item, index) => {
        const hoursText = item.dt_txt.split(' ')[1].split(':')[0].trim();
        const hours     = Number(item.dt_txt.split(' ')[1].split(':')[0]);
        const dayTag    = (hours < 6 || hours > 15) ? 'night' : 'day';
        const iconId    = `wi wi-owm-${dayTag}-${item.weather[0].id}`;
        const amPM      = (hours < 12 || hours === 0) ? 'AM' : 'PM';
        const hoursDisplay = (hours > 12 ? hours - 12 : hours).toLocaleString('en-US', { minimumIntegerDigits: 2 }) + ' ' + amPM;

        if (item.main.temp_min <= tempRange.min) { tempRange.min = item.main.temp_min; tempRange.min_hours = hoursDisplay; }
        if (item.main.temp_max >= tempRange.max) { tempRange.max = item.main.temp_max; tempRange.max_hours = hoursDisplay; }

        rows[hoursText].hours         = <td>{hoursText}</td>;
        rows[hoursText].main_temp     = <td className='weatherforecast-temp'     key={daily.id+'-temp'+index}    >{Math.round(item.main.temp)}&deg;F</td>;
        rows[hoursText].main_temp_max = <td className='weatherforecast-temp_max' key={daily.id+'-temp_max'+index}>{Math.round(item.main.temp_max)}&deg;F</td>;
        rows[hoursText].main_temp_min = <td className='weatherforecast-temp_min' key={daily.id+'-temp_min'+index}>{Math.round(item.main.temp_min)}&deg;F</td>;
        rows[hoursText].icon          = <td className='weatherforecast-icon'     key={daily.id+'-icon'+index}    ><i className={iconId}></i></td>;
        rows[hoursText].range         = <td className='weatherforecast-range'    key={daily.id+'-range'+index}   ><Slider value={[item.main.temp_min,item.main.temp_max]} orientation="vertical" range min={tempRange.min - 2} max={tempRange.max + 2} /></td>;
    });

    const displayRows = { hours: [], main_temps: [], main_temp_maxs: [], main_temp_mins: [], icons: [], ranges: [] };

    Object.keys(rows).sort().forEach((key) => {
        displayRows.hours.push(rows[key].hours);
        displayRows.main_temps.push(rows[key].main_temp);
        displayRows.main_temp_maxs.push(rows[key].main_temp_max);
        displayRows.main_temp_mins.push(rows[key].main_temp_min);
        displayRows.ranges.push(rows[key].range);
        displayRows.icons.push(rows[key].icon);
    });

    let d = new Date(1970, 0, 1);
    d.setUTCSeconds(daily.forecast[0].dt);

    const title     = `${WEEKDAYS[d.getDay()]}, ${('0' + d.getDate()).slice(-2)}`;
    const condition = daily.forecast[0].weather[0].description.charAt(0).toUpperCase() + daily.forecast[0].weather[0].description.slice(1);

    return (
        <div key={daily.id}>
            <div className='weatherforecast-title'>{daily.location}</div>
            <div className='weatherforecast-subtitle'>{title}</div>
            <table className='weatherforecast-daily-table'>
            <thead>
                <tr><th>12a</th><th>03a</th><th>06a</th><th>09a</th><th>12p</th><th>03p</th><th>06p</th><th>09p</th></tr>
            </thead>
            <tbody>
                <tr>{displayRows.icons}</tr>
                <tr>{displayRows.main_temps}</tr>
                <tr>{displayRows.ranges}</tr>
            </tbody>
            </table>
            <div className='weatherforecast-condition'>{condition}</div>
            <div className='weatherforecast-temp-range'>
                <div><span className='weather-temp'>{Math.round(tempRange.min)}&deg;F</span> <span>low / high</span> <span className='weather-temp'>{Math.round(tempRange.max)}&deg;F</span></div>
            </div>
        </div>
    );
}

const fetcher = (url) => fetch(url).then((response) => response.json());

const WeatherForecastAnswer = (props) => {
    const { latitude, longitude } = usePosition(false, { enableHighAccuracy: true });

    const { data: currentWeatherAndForecastData, error: apiError } = useSWR(
        () => API_BASE_URL.getCurrentWeatherURL + '?lat=' + latitude + '&lon=' + longitude, fetcher
    );

    if (apiError) {
        console.log('>>> API error: ', apiError);
        return "Error fetching data...";
    }

    if (!(latitude && longitude)) return 'Retrieving location...'
    if (!currentWeatherAndForecastData) return 'Retrieving current weather...';

    const currentWeatherData = currentWeatherAndForecastData.current;
    const location           = `${currentWeatherData.name}, ${currentWeatherData.sys.country}`;
    const sorted             = [ ...currentWeatherAndForecastData.forecast.list ];

    sorted.sort((a, b) => a.dt - b.dt);

    const forecast = {
        "data": [
            { id: 0, location: location, forecast: currentWeatherData   },
            { id: 1, location: location, forecast: sorted.slice(0,  8 ) },
            { id: 2, location: location, forecast: sorted.slice(8,  16) },
            { id: 3, location: location, forecast: sorted.slice(16, 24) },
            { id: 4, location: location, forecast: sorted.slice(24, 32) },
            { id: 5, location: location, forecast: sorted.slice(32, 40) },
        ]
    };

    return (
        <div>
            <div className="weatherforecast-answer">
                <Carousel 
                    value={forecast.data} 
                    itemTemplate={forecastTemplate} 
                    numVisible={1} 
                    numScroll={1}
                    />
            </div>
            <Timestamp />
        </div>
    );
}

export default WeatherForecastAnswer;