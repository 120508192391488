import React from 'react';
import Timestamp from '../../components/Timestamp/Timestamp';
// import Deals from '../../components/Deals/DealsByReddit';

import Deals from '../../components/Deals/DealsBySlickdeals';

import './DealsAnswer.css';

const DealsAnswer = (props) => {
    const utterance = props.state.utterance;
    const topic = utterance;

    props.scrollIntoView();

    return(
        <div>
            <div className='deals-answer-card'><Deals topic={topic} /></div>
            <Timestamp />
        </div>
    );
}

export default DealsAnswer;
