import './AssistantHeader.css';

const AssistantHeader = (props) => {
    return (
        <div className="assistant-header-container">
            <span className='assistant-header-title'>
                <span className="assistant-header-bot-type">{props.state.headerText}</span>
                <span className="assistant-header-bot-name">{props.state.assistantName}</span>
            </span>
        </div>
    );
};

export default AssistantHeader;