import React, { useState } from 'react';
import parse from "html-react-parser";

import ClickToFlipCard from '../../components/ClickToFlipCard/ClickToFlipCard';
import Timestamp       from '../../components/Timestamp/Timestamp';
import { linkify }     from '../../lib/utilities';

import './FAQAnswer.css';

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';
const defaultAvatarThumbnail = `/${homePage}/images/avatar-agents/generic-knowledge-agent-book.jpg`;

const AGENT_CONFIGS = {
    '@covid'      : require('../../agents/knowledge-base-covid.json'),
    '@hca'        : require('../../agents/knowledge-base-hca.json'),
    '@hcgov'      : require('../../agents/knowledge-base-hcgov.json'),
    '@studentaid' : require('../../agents/knowledge-base-studentaid.json'),
    '@finance'    : require('../../agents/knowledge-base-finance.json'),
};

const AGENT_NAMES = {
    "Answer Questions on COVID-19"           : "@covid",
    "Answer Questions on Finance"            : "@finance",
    "Answer Questions on General Healthcare" : "@hca",
    "Answer Questions on Gov Healthcare"     : "@hcgov",
    "Answer Questions on Student Aid"        : "@studentaid",
};

const cardFront = (props) => {
    const { header, main } = props;
    const handleClick = (e) => { e.stopPropagation(); };

    return (
        <div className='ctf-card-face'>
            <div className='header row'>
                <div className='column left'  >&nbsp;</div>
                <div className='column middle'>
                    <div className='data-item'>
                        <img className='avatar-thumbnail' src={header.picture || defaultAvatarThumbnail} alt='avatar thumbnail' />
                        <span><strong>{header.name}</strong></span>&nbsp;/&nbsp;<span>{header.email}</span>
                    </div>
                </div>
                <div className='column right' >&nbsp;</div>
            </div>
            <div className='content' onClick={e => handleClick(e)}>{main}</div>
        </div>
    );
}

const FAQAnswer = (props) => {
    const [ answer,    setAnswer    ] = useState(null);
    const [ agentInfo, setAgentInfo ] = useState(null);

    if (!agentInfo) {
        const agentConfig = 
            AGENT_CONFIGS[props.state.userMessage.keyword]                 // pick agent based on directive
            || AGENT_CONFIGS[AGENT_NAMES[props.state.intentDisplayName]];  // pick agent based on dialog intent

        if (!agentConfig) return `No agent found to handle ${props.state.userMessage.keyword}`;

        setAgentInfo({ 
            handle    : agentConfig.knowledge_base.handle,
            name      : agentConfig.knowledge_base.name,
            id        : agentConfig.knowledge_base.id,
            documents : agentConfig.documents,
            avatar    : `/${homePage}/images/avatar-agents/${agentConfig.knowledge_base.avatar}`
        });
    }

    if (!agentInfo) return (`Looking for an answer from ${props.state.userMessage.keyword}...`);

    if (!answer) {
        const headers = { 'Content-Type': 'application/x-www-form-urlencoded', 'Accept': 'application/json' };
        const method  = 'POST';

        const body    = new URLSearchParams({
            'knowledgeBaseID' : agentInfo.id,
            'message'         : props.state.userMessage.directive || props.state.query,
            'sessionPath'     : '',
        });

        fetch(process.env.REACT_APP_SEARCH_AGENT_API_TEXT_URL, { method: method, headers: headers, body: body })
            .then(response => response.json())
            .then(json     => setAnswer(json));
    }

    if (!answer || !agentInfo) return (`Still looking for an answer from ${agentInfo.handle}...`);

    console.log('>>> Answer: ', answer);
    console.log('>>> Confidence: ', answer.data[0]?.queryResult?.intentDetectionConfidence);
    console.log('>>> Answers: ', answer.data[0]?.queryResult.knowledgeAnswers.answers);

    if (answer && !answer.data[0].queryResult.knowledgeAnswers) {
        return ("Sorry, there's no information about this...");
    }

    const cardInfo = { id: Date.now() };

    const docPathElements = answer.data[0].queryResult.knowledgeAnswers?.answers[0].source.split('/');
    const docName = agentInfo.documents[docPathElements[docPathElements.length - 1]]?.title || docPathElements[docPathElements.length - 1];
    const docLink = agentInfo.documents[docPathElements[docPathElements.length - 1]]?.url   || null;

    if (answer && answer.data[0].queryResult.knowledgeAnswers) {
        const header = {
            picture : agentInfo.avatar,
            name    : agentInfo.name,
            email   : agentInfo.handle
        };

        const plainTextAnswer = answer.data[0].queryResult.knowledgeAnswers.answers[0].answer;
        let linkifiedAnswer = plainTextAnswer;

        if (plainTextAnswer.includes(' https://') 
            || plainTextAnswer.includes(' http://')
            || plainTextAnswer.includes('(https://')
            || plainTextAnswer.includes('(http://')
        ) {
            linkifiedAnswer = linkify(plainTextAnswer);
        }

        const parsedAnswer = parse(linkifiedAnswer);

        const main = (
            <div>
                <div className='title'>{ answer.data[0].queryResult.knowledgeAnswers.answers[0].faqQuestion }</div>
                <div className='text'>
                    {parsedAnswer}
                    <span className="divider">/</span>
                    <span className="byline"><a href={ docLink } target='_blank' alt='link to source document'>{ docName }</a></span>
                </div>
            </div>
        );
    
        cardInfo.frontFace = cardFront({ header: header, main: main});

        cardInfo.backFace  = (
            <div className='answer-source'>
                <img className='avatar-thumbnail' src={agentInfo.avatar} alt='agent thumbnail' />
                <div className='knowledge-base'>{ agentInfo.name }</div>
                <div className='handle'>{ agentInfo.handle }</div>
            </div>
        );

        return (
            <div>
                <div className="clicktoflip-answer with-header"><ClickToFlipCard { ...cardInfo } /></div>
                <Timestamp />
            </div>
        );
    }
};

export default FAQAnswer;