import { useEffect } from "react";
import { useAuth } from "../../lib/auth-context";
import { useLocation, useNavigate } from "react-router-dom";
import { OAuthLogin, oAuthRefreshTokenSetup } from '../../lib/oauth-with-google';

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';

const LoginPage = () => {
    const { login, user } = useAuth();
    const { state } = useLocation();
    const navigate = useNavigate(); 

    const onOAuthLoginSuccess = (authResult) => {
        oAuthRefreshTokenSetup(authResult);
        sessionStorage.setItem('accessToken', authResult.accessToken);
        login({ tokenId: authResult.tokenId });
    };

    const onOAuthLoginFailure = (authResult) => {
        console.log('>>> OAuth login failed: ', authResult);
        alert(authResult);
    };

    useEffect(() => { user?.email && navigate(state?.targetPath || `/${homePage}/dashboard`); }, [ user ]);

    return (
        <div className="page-content">
            <p>You may <span className="inline-auth-prompt"><OAuthLogin onSuccess={onOAuthLoginSuccess} onFailure={onOAuthLoginFailure} /></span> to access your dashboard and personalize your experience.</p>
        </div>
    );
};

export default LoginPage;