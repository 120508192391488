import React, {useState} from 'react';
import Timestamp from '../../components/Timestamp/Timestamp';
import Timer from '../../components/Timer/Timer';

import './TimerAnswer.css';

function hasNumber(utterance) {
    return /\d/.test(utterance);
}

const TimerAnswer = (props) => {
    const [ duration, setDuration ] = useState(null);

    if (duration == null) {
        let [ amount, unit ] = [ 1, '' ];

        if (props.state.parameters && props.state.parameters.fields && props.state.parameters.fields.duration.structValue) {
            ({ amount, unit } = props.state.parameters.fields.duration.structValue.fields);

            if (unit.stringValue === 's') {
                setDuration(amount.numberValue / 60);
            }
            else {
                setDuration(amount.numberValue);
            }
        }
    }

    let mins = duration || 1;

    return(
        <div>
            <div className='timer-answer'>
                <Timer mins={mins} />
            </div>
            <Timestamp />
        </div>
    );
}

export default TimerAnswer;