import { useState } from 'react';
import useSWR from 'swr';
import { Carousel } from 'primereact/carousel';
import { linkify } from '../../lib/utilities';
import parse from "html-react-parser";

import './NewsByNewsAPIWithCarousel.css';

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';

function strip(html){
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}

const fetcher = (url) => fetch(url).then((response) => response.json());

const newsArticleTemplate = (article) => {
    let thumbnailURL = article["urlToImage"] ? article["urlToImage"].replace("width=1280", "width=75") : '';
    let byline       = article["author"] || article.source.name;

    if (byline.startsWith('[')) {
        const json = JSON.parse(byline);
        if (json[0] && json[0].name) byline = json[0].name;
    }

    if (!byline || byline.startsWith("http") || byline.startsWith("<") || byline.startsWith("[")) {
        byline = article.source.name || "Editors";
    }

    return (
        <div className='article'>
            <div className='source'>{article.source.name}</div>
            <div className="title"><a href={article["url"]} target="_blank" rel="noopener noreferrer">{article.title.split(' - ')[0]}</a></div>
            <div className="thumbnail"><img src={thumbnailURL} alt="" onError={(e) => e.target.src=`/${homePage}/images/news-hero-image.jpg`}/></div>
            <div className="published-date">{new Date(article.publishedAt).toLocaleString()}</div>
            <div>
                <span className="abstract">{parse(linkify(strip(article.description)))}</span>
                <span className="divider">/</span>
                <span className="byline">{parse(linkify(strip(byline)))}</span>
            </div>
        </div>
    );
};

const NewsByNewsAPI = (topic) => {
    const [ news, setNews ] = useState();

    const tzoffset   = (new Date()).getTimezoneOffset() * 60000;
    const dateString = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1).split('T')[0];
    const newsFeed   = `newsapi-top-headlines-${topic["topic"]}-${dateString}.json`;
    const newsAPIURL = `/${homePage}/data/newsapi/${newsFeed}`;

    const { data, error } = useSWR(newsAPIURL, fetcher);
 
    if (error) {
        console.log('>>> Error: ', error);
        return "Error fetching news...";
    }
 
    if (!data && !news) return "Loading news...";
    if (data && !news)  setNews(data);
    if (!news)          return "Loading news...";

    const isEmptyArticle = (article) => { return article.description === null; };

    let found = news.articles.findIndex(isEmptyArticle);

    while (found != -1) {
        news.articles.splice(found, 1);
        found = news.articles.findIndex(isEmptyArticle);
    }

    return (
        <Carousel 
            className='news'
            value={news.articles} 
            itemTemplate={newsArticleTemplate} 
            numVisible={1} 
            numScroll={1} 
            />
    );
};

export default NewsByNewsAPI;