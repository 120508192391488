import React, { Component } from 'react';

import './Timer.css';

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';
const fanfare  = `/${homePage}/sounds/fanfare.mp3`;

class Timer extends Component {
    constructor(props) {
        super(props);

        const startMins = props.mins || 2;

        this.state = {
            time: {
                hours: 0,
                minutes: 0,
                seconds: 0,
                milliseconds: 0,
            },
            duration: startMins * 60 * 1000,
            timer: null
        };

        this.startTimer = this.start.bind(this);
        this.stopTimer = this.stop.bind(this);
    }
    
    msToTime(duration) {
        let milliseconds = parseInt((duration % 1000));
        let seconds = Math.floor((duration / 1000) % 60);
        let minutes = Math.floor((duration / (1000 * 60)) % 60);
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        
        hours = hours.toString().padStart(2, '0');
        minutes = minutes.toString().padStart(2, '0');
        seconds = seconds.toString().padStart(2, '0');
        milliseconds = milliseconds.toString().padStart(3, '0');
        
        return {
            hours,
            minutes,
            seconds,
            milliseconds
        };
    }
    
    componentDidMount() {
        this.setState(() => ({
            time: this.msToTime(
                this.state.duration
            )
        }));
    }
    
    start() {
        if (!this.timer) {
            this.state.startTime = Date.now();
            this.timer = window.setInterval(() => this.run(), 10);
        }
    }

    run() {
        const diff = Date.now() - this.state.startTime;
        
        let remaining = this.state.duration - diff;

        if (remaining < 0) {
            remaining = 0;
        }

        this.setState(() => ({
            time: this.msToTime(remaining)
        }));

        if (remaining === 0) {
            new Audio(fanfare).play();
            window.clearTimeout(this.timer);
            this.timer = null;

            this.setState(() => ({
                time: this.msToTime(
                    this.state.duration
                )
            }));
        }
    }

    stop() {
        if (this.timer) {
            window.clearInterval(this.timer);
            this.timer = null;
        }
    }
    
    render() {
        return ( 
            <div>
                <div className='timer-answer-start-button' onClick={this.startTimer}>Start</div>
                <div className='timer-answer-start-last-button' onClick={this.stopTimer}>Stop</div>
                <div className="timer div-table">
                    <div className='div-row'>
                        <span className='timer-answer-timer'>
                            {this.state.time.hours.toLocaleString('en', {minimumIntegerDigits:2})}
                            :{this.state.time.minutes.toLocaleString('en', {minimumIntegerDigits:2})}
                            :{this.state.time.seconds.toLocaleString('en', {minimumIntegerDigits:2})}
                        </span>
                        <span className='timer-answer-timer timer-answer-timer-millisec'>
                            .{this.state.time.milliseconds.toLocaleString('en', {minimumIntegerDigits:3})}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Timer;
