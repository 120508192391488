import { useState } from 'react';
import { useAssistantConfig } from '../lib/assistant-config-context';
import { useAuth } from '../lib/auth-context';

import Chatbot                     from "react-chatbot-kit";
import MessageParserWithDialogFlow from "./MessageParserWithDialogFlow";
import ActionProvider              from "./ActionProvider";

import { ReactComponent as ChatbotLaunchIcon } from '../assets/icons/chatbot-up-plain.svg';
import { ReactComponent as ChatbotCloseIcon  } from '../assets/icons/chatbot-down.svg';

import 'react-chatbot-kit/build/main.css'
import './style.css';
import './widgets.css';

const AssistantLauncher = (props) => {
    const [ showBot, toggleBot ] = useState(true);
    const { user } = useAuth();
    const { assistantConfig } = useAssistantConfig();

    const config = assistantConfig(user);

    return (
        <span>
            { showBot && (
                <Chatbot 
                    config={config} messageParser={MessageParserWithDialogFlow} actionProvider={ActionProvider} 
                    headerText={config.headerText} placeholderText ={config.placeholderText}
                />
            )}
            <button className='app-chatbot-button' onClick={() => toggleBot((prev) => !prev)}>
            { showBot ?
                <ChatbotCloseIcon  className="app-chatbot-button-down-icon" /> :
                <ChatbotLaunchIcon className="app-chatbot-button-up-icon" />
            }
            </button>
        </span>
    );
};

export default AssistantLauncher;