import { createContext, useContext, useState } from 'react';
import useSWR from 'swr';

const AuthContext = createContext(false);

export const AuthProvider = (props) => {
    const authCacheID = 
        process.env.REACT_APP_AUTH_API_BASE_URL + '/' +
        process.env.REACT_APP_AUTH_API_BASE_PATH + '/' +
        'cache';

    const [ user, setUser ] = useState();
    const [ loading, setLoading ] = useState(true);
    const [ loggedOut, setLoggedOut ] = useState(true);
    let   { userInfo, error, mutate } = useSWR(authCacheID);

    const login = async (loginData) => {
        const result = await fetch(
            process.env.REACT_APP_AUTH_API_LOGIN_URL, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({ id_token: loginData.tokenId }),
                headers: { 'Content-Type': 'application/json' }
            }
        )
        .then (
            (response) => {
                if (response.status !== 200) {
                    console.log('>>> Response status: ', response.status);
                }

                return response;
            }
        )
        .catch (
            (error) => {
                console.log('>>> POST error: ', error);
            }
        );

        userInfo = await result.json();

        if (userInfo.error) throw new Error(userInfo.error);

        document.cookie = 'oauth-app-auth-token=logged-in';
        document.cookie = `email=${userInfo.email};`;
        setUser({ ...userInfo });
        setLoading(!userInfo && !userInfo.error);
        setLoggedOut(false);

        mutate(authCacheID);
    };

    const logout = async () => {
        await fetch(
            process.env.REACT_APP_AUTH_API_LOGOUT_URL, { 
                method: 'DELETE', 
                credentials: 'include' 
            }
        );

        document.cookie = 'oauth-app-auth-token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'email=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        setUser({});
        setLoggedOut(true);

        mutate(authCacheID);
    };

    return (
        <AuthContext.Provider 
            value={{
                login, logout, user, loading, loggedOut, error
            }}
            { ...props }
        />
    );
}

export const useAuth = () => useContext(AuthContext);