import './ContentForMyContacts.css';

const homePage      = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';
const avatar        = `/${homePage}/images/avatar-assistant/ginger-tea-512.png`;
const defaultAvatar = `/${homePage}/images/avatar-system/generic-oval-512.png`;
const title         = 'Google Contacts';

const findContacts = async (props) => {
    const accessToken = sessionStorage.getItem('accessToken');
    const { query }   = props;
    const apiURL      = `https://people.googleapis.com/v1/people:searchContacts?query=${query}&readMask=names,emailAddresses,phoneNumbers,photos`;

    try {
        return await fetch(apiURL, { headers: { Authorization: `Bearer ${accessToken}` } }).then(response => response.json());
    }
    catch (error) {
        return { message: error.message };
    }
};

const template = (item) => {
    const photoURL = item.photos ? item.photos[0].url : defaultAvatar;

    if (item.message) return <div className='contact-info'>{item.message}</div>;

    return (
        <div className="contact-info">
            <div className="avatar">
                <img className = 'avatar-thumbnail' 
                    src        = {photoURL} 
                    alt        = 'avatar thumbnail' 
                    onError    = {({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src     = defaultAvatar;
                    }}
                />
            </div>
            <div className="display-name">{item.names[0].displayName}</div>
            <div className="email-address">
                <a href={`mailto:${item.emailAddresses[0].value}`}>{item.emailAddresses[0].value}</a>
            </div>
        </div>
    ); 
};

const ContentForMyContacts = async (props) => {
    const { query } = props;
    const source    = { picture: avatar, name: 'Ginger T', handle: '@ginger' };
    const data      = [ ];

    const apiData = await findContacts({ query: query.fields.query.stringValue });

    apiData.results?.reduce(
        (previous, current) => {
            data.push({ ...current.person });
        }, data
    );

    if (!data.length) data.push({ message: 'No contacts found' });
  
    return {
        data     : data,
        template : template,
        source   : source,
        title    : title
    };
};

export default ContentForMyContacts;