import AvatarIcons from "../../assistant/AvatarIcons";
import "./UserAvatar.css";

const UserAvatar = (props) => {
    return <div className="user-avatar-container">
        <img className="user-avatar-icon" 
            src={props.picture || 
                (props.icon && AvatarIcons.userIcons[props.icon]) ||
                AvatarIcons.userIcons[0]
            } 
            alt="user-avatar-icon"
            referrerPolicy="no-referrer"
            >
        </img>
    </div>;
};
    
export default UserAvatar;