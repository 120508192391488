import { createCustomMessage } from 'react-chatbot-kit';
import { dialogFlowResponder } from '../responders/DialogFlowResponder/DialogFlowResponder';

const RESPONSE_HANDLERS = {
    "Answer Questions on COVID-19"           : "carouselAnswer",
    "Answer Questions on Finance"            : "carouselAnswer",
    "Answer Questions on General Healthcare" : "carouselAnswer",
    "Answer Questions on Gov Healthcare"     : "carouselAnswer",
    "Answer Questions on Student Aid"        : "carouselAnswer",
    "Display Timer"                          : "timerAnswer",
    "Display Clock"                          : "clockAnswer",
    "Display Calendar"                       : "calendarAnswer",
    "Display Calculator"                     : "calculatorAnswer",
    "Display Elected US Officials"           : "carouselAnswer",
    "Display My Contacts"                    : "carouselAnswer",
    "Display Weather"                        : "weatherAnswer",
    "Display IP"                             : "ipAddressAnswer",
    "Display Map"                            : "cardAnswer",
    "Display News"                           : "newsAnswer",
    "Display QR"                             : "qrAnswer",
    "Find Lyrics"                            : "lyricsAnswer",
    "Find Videos"                            : "videoAnswer",
    "Find Deals"                             : "carouselAnswer",
    "Play Pac-Man"                           : "playPacmanAnswer",
    "Play Snake"                             : "playSnakeAnswer",
    "Play Wordle"                            : "playWordleAnswer",
    "Tell a Joke"                            : "jokeAnswer",
    "Tell a Riddle"                          : "funRiddleAnswer",
    "Default Fallback Intent"                : "searchAnswer",
};

const AGENTS = {
    '@deals'      : { handler: 'carouselAnswer', intent: 'Find Deals' },
    '@hca'        : { handler: 'carouselAnswer', intent: 'Answer Questions on General Healthcare' },
    '@hcgov'      : { handler: 'carouselAnswer', intent: 'Answer Questions on Gov Healthcare' },
    '@covid'      : { handler: 'carouselAnswer', intent: 'Answer Questions on COVID-19' },
    '@studentaid' : { handler: 'carouselAnswer', intent: 'Answer Questions on Student Aid' },
    '@finance'    : { handler: 'carouselAnswer', intent: 'Answer Questions on Finance' },
};

const KB_CONFIG_PREFIX = '../../agents/knowledge-base';

class ActionProvider {
    constructor(createChatbotMessage, setStateFunc, createClientMessage) {
        this.createChatbotMessage = createChatbotMessage;
        this.setState             = setStateFunc;
        this.createClientMessage  = createClientMessage;
    }

    handleAll(props) {
        this.setState((state) => ({ ...state, ...props }));

        this.setState((prev) => ({ 
            ...prev, messages: [ 
                ...prev.messages, 
                createCustomMessage('Hohoho', 'parrotBoxAnswer'),
                this.createChatbotMessage('Anything else I can help you with?', { withAvatar: true, delay: 1000 }),
            ] 
        }));
    }

    answerWith(props) {
        this.setState((state) => ({ ...state, ...props }));

        this.setState((prev) => ({ 
            ...prev, messages: [ 
                ...prev.messages,
                createCustomMessage(props.utterance, props.handler),
                this.createChatbotMessage('Anything else I can help you with?', { withAvatar: true, delay: 1000 }),
            ] 
        }));
    }

    directMessageWith(props) {
        const agentConfigFile = `${KB_CONFIG_PREFIX}-${props.userMessage.keyword.substring(1)}.json`;

        const handler = AGENTS[props.userMessage.keyword]?.handler || 'parrotBoxAnswer';

        this.setState((state) => ({ ...state, ...props, agentConfigFile: agentConfigFile, intentDisplayName: AGENTS[props.userMessage.keyword]?.intent }));

        this.setState((prev) => ({ 
            ...prev, messages: [ 
                ...prev.messages,
                createCustomMessage(props.utterance, handler),
                this.createChatbotMessage('Anything else I can help you with?', { withAvatar: true, delay: 1000 }),
            ] 
        }));
    }

    async respondWith(props) {
        this.setState((state) => ({ ...state, ...props }));

        const { data, sessionPath, responseText, intentDisplayName, parameters } = 
            await dialogFlowResponder({ ...props, sessionPath: (props.sessionPath || '') });

        this.setState((state) => ({ ...state, sessionPath: sessionPath, intentDisplayName: intentDisplayName, parameters: parameters }));

        console.log('>>> Intent display name: ', intentDisplayName);

        if (data.queryResult.allRequiredParamsPresent) {
            RESPONSE_HANDLERS[intentDisplayName] ?
                this.setState((state) => ({ 
                    ...state, 
                    messages: [ 
                        ...state.messages, 
                        createCustomMessage(props.utterance, RESPONSE_HANDLERS[intentDisplayName]) 
                    ]
                })) :
                this.setState((state) => ({ 
                    ...state, 
                    messages: [ 
                        ...state.messages, 
                        this.createChatbotMessage(responseText, { withAvatar: true }) 
                    ] 
                }));

            if (intentDisplayName !== 'Default Welcome Intent')
                this.setState((state) => ({ 
                    ...state, 
                    messages: [ 
                        ...state.messages, 
                        this.createChatbotMessage('Anything else I can help you with?', { withAvatar: true, delay: 1000 }) 
                    ] 
                }));
        }
        else {
            this.setState((state) => ({ ...state, messages: [ ...state.messages, this.createChatbotMessage(responseText, { withAvatar: true }) ] }));
        }
    }
}

export default ActionProvider;