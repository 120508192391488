import { useState } from 'react';
import useSWR from 'swr';
import Timestamp from '../../components/Timestamp/Timestamp';
import ClickToFlipCard from '../../components/ClickToFlipCard/ClickToFlipCard';

const fetcher = (url, method, headers, body) => fetch(url, { method: method, headers: headers, body: body }).then((response) => response.text());

function buildLinkListMarkup(response) {
    const finalResponse = response.substring(response.indexOf('?') + 1);

    const handleClick = (e) => {
        e.stopPropagation();
    }

    const links = finalResponse.trim().split('\n').reduce(
        (accum, value, index, splits) => {
            if (index % 2 === 0) accum.push(
                { text: value, url: splits[index+1], id: index+1 } 
            );
        
            return accum;
        }, []
    );

    const listMarkup = links.map(
        (link) => {
            if (!link.text.includes('No Result!')) return (
                <li key={link.id} className="link-list-item">
                    <a href={link.url} target="_blank" rel="noopener noreferrer" className="link-list-item-url" onClick={e => handleClick(e)}>
                        {link.text}
                    </a>
                </li>
            )
        }    
    );

    return <div className='answer-markup'><span>Suggested links:&nbsp;</span><ul className="link-list">{listMarkup}</ul></div>;
}

function buildPlainTextMarkup(response) {
    let finalResponse = response;

    if (finalResponse.startsWith("> did you mean")) {
        finalResponse = finalResponse.substring(finalResponse.indexOf('?') + 1);
    }

    if (finalResponse.length < 500) {
        finalResponse = finalResponse.replace(/(\r\n|\n|\r)/gm, " ");
    }

    const regex = /\s\d+\)/g;
    const found = finalResponse.match(regex);

    if (found && (found.length > 1)) {
        const allItems = finalResponse.split(regex);
        const openingLine = allItems[0];

        const items = allItems.map(
            (item, index) => {
                if (index === 0 ) return '';
                return <li key={index} className="link-list-item">{item.replace(/\d\-\s/, '').replace('...', '')}</li>
            }
        );

        return <div className='answer-markup'><span>{openingLine}&nbsp;</span><ul className="link-list">{items}</ul></div>;
    }

    const lines = finalResponse.trim().split(/(\r\n|\n|\r)/).map(
        (item, index) => {
            if (item.trim() !== '') return <li key={index} className="plain-text-item">{item}</li>;
            return '';
        }
    );

    return <div className='answer-markup'><ul className="plain-text">{lines}</ul></div>;
}

function answerMarkup(response) {
    if (response.includes("No Result!") && response.trim().replace('No Result!', '').length > 0) {
        return buildLinkListMarkup(response);
    }

    return buildPlainTextMarkup(response);
}
        
const SearchAnswer = (props) => {
    const cardInfo = { id: Date.now() };
    const [ answer, setAnswer ] = useState();

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded', 'Accept': '*/*' };
    const method  = 'POST';
    const body    = `options=-r&question=${props.state.utterance}`

    const { data, error } = useSWR(
        [ process.env.REACT_APP_HOOKS_TUXI_URL, method, headers, body ], fetcher
    );
 
    if (error) {
        console.log('>>> Error: ', error);
        return "Error fetching data...";
    }
 
    if (!data && !answer) { return "Searching for answer..."; }
    if (data && !answer)  { setAnswer(data); }
    if (!answer)          { return "Searching for answer..."; }

    if (answer) {
        cardInfo.frontFace = answerMarkup(answer);
        cardInfo.backFace  = 'Google Web Search';

        return (
            <div>
                <div className="clicktoflip-answer search"><ClickToFlipCard { ...cardInfo } /></div>
                <div className="raw-search-result">{answer}</div>
                <Timestamp />
            </div>
        );
    }
};

export default SearchAnswer;