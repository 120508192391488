const USER_MESSAGE_TYPE = Object.freeze({
    command:   1,
    directive: 2,
    utterance: 3,
});

const MESSAGE_HANDLERS = Object.freeze({
    default : 'parrotBoxAnswer',
    dialog  : 'dialogFlowAnswer',
});

const DEFAULT_KEYWORD = Object.freeze('/dialog');

class Message {
    constructor(attributes) { 
        this.utterance = attributes.utterance;
        this.message   = attributes.utterance.slice(attributes.utterance.indexOf(' ')).trim();
        this.keyword   = attributes.utterance.split(' ')[0];
    }

    input()            { return this.utterance; }
    query()            { return this.message; }
    lowerCaseMessage() { return this.utterance.toLowerCase().trim(); }
    isCommand()        { return this instanceof(CommandMessage); }
    isDirective()      { return this instanceof(DirectiveMessage); }
    isPlain()          { return this instanceof(PlainMessage); }
}

class CommandMessage extends Message {
    constructor(attributes) { 
        super(attributes); 
        this.messageType = USER_MESSAGE_TYPE.command;
        this.handler     = MESSAGE_HANDLERS.default;
    }

    command() { return this.message; }
}

class DirectiveMessage extends Message {
    constructor(attributes) { 
        super(attributes); 
        this.messageType = USER_MESSAGE_TYPE.directive;
        this.handler     = MESSAGE_HANDLERS.dialog;
    }

    directive() { return this.message; }
}

class PlainMessage extends Message {
    constructor(attributes) { 
        super(attributes);
        this.messageType = USER_MESSAGE_TYPE.utterance;
        this.handler     = MESSAGE_HANDLERS.dialog;
        this.message     = this.utterance;
        this.keyword     = DEFAULT_KEYWORD;
    }
}

class MessageFactory {
    create = (message) => {
        if (message.startsWith('/')) return new CommandMessage({ utterance: message });
        if (message.startsWith('@')) return new DirectiveMessage({ utterance: message });
        return new PlainMessage({ utterance: message });
    };
}

const Messages = new MessageFactory();

export { USER_MESSAGE_TYPE, Message, Messages };