import React from 'react';
import Timestamp from '../../components/Timestamp/Timestamp';
import Calculator from '../../components/Calculator/Calculator';

import './CalculatorAnswer.css';

const CalculatorAnswer = (props) => {
    return(
        <div>
            <div className='calculator-answer'>
                <Calculator />
            </div>
            <Timestamp />
        </div>
    );
}

export default CalculatorAnswer;
