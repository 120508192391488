import React, { useState } from 'react';
import useSWR from 'swr';
import { Form, Field } from 'react-final-form'
import FormAutoSave from '../utility/FormAutoSave';
import { useAuth } from "../../lib/auth-context";

import './DashboardPage.css';

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';
const defaultAvatarThumbnail = `/${homePage}/images/avatar-system/generic-oval-512.png`;

const sleep = (duration) => new Promise(res => setTimeout(res, duration));

const fetcher = (url, method, headers, body) => fetch(url, { method: method, headers: headers, body: body })
    .then(response => {
        let json = {};

        try {
            json = response.json();
        }
        catch (error) {
            console.log('>>> Error: ', error);
        }

        return json;
    })
    .catch(e => console.log('>>> Error: ', e));

const save = async (difference, values) => {
    console.log('>>> Saving difference: ', difference);
    console.log('>>> Saving values: ', values);

    const response = await fetch(
        process.env.REACT_APP_USER_PREFERENCES_API_URL + '/' + values.email, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', 'Accept': 'application/json'
            },
            body: JSON.stringify({ spreadsheet_id: values.sheetID, sheet_id: values.tabID })
        }
    ).then(response => response.json());

    console.log('>>> Upsert response: ', response);

    sleep(1000);
};

const listCalendars = async (props) => {
    try {
        return await fetch(
            'https://www.googleapis.com/calendar/v3/users/me/calendarList',
            { headers: { Authorization: `Bearer ${props.accessToken}` } }
        )
        .then(response => response.json());
    }
    catch (error) {
        return { message: error.message };
    }
};

const listContacts = async (props) => {
    try {
        return await fetch(
            'https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses',
            { headers: { Authorization: `Bearer ${props.accessToken}` } }
        )
        .then(response => response.json());
    }
    catch (error) {
        return { message: error.message };
    }
};

const DashboardPage = () => {
    const { user } = useAuth();
    const [ userPrefs, setUserPrefs ] = useState();
    const [ calendars, setCalendars ] = useState();
    const [ contacts,  setContacts  ] = useState();
    const { data, error } = useSWR(process.env.REACT_APP_USER_PREFERENCES_API_URL + '/' + user.email, fetcher);

    if (error) {
        console.log('>>> Error: ', error);
        return "Error fetching data...";
    }
 
    if (!data && !userPrefs) return "Loading data...";
    if (data && !userPrefs) setUserPrefs(data);
    if (!userPrefs) return "Loading data...";

    const accessToken = sessionStorage.getItem('accessToken');

    if (!calendars) {
        listCalendars({ accessToken: accessToken }).then(calendars => setCalendars(calendars));
    };

    if (!calendars) return 'Loading calendars...';

    console.log('>>> Calendars: ', calendars);

    if (!contacts) {
        listContacts({ accessToken: accessToken }).then(contacts => setContacts(contacts));
    }

    if (!contacts) return 'Loading contacts...';

    console.log('>>> Contacts: ', contacts);

    return (
        <Form onSubmit={save} initialValues={{ sheetID: userPrefs.spreadsheet_id || '', tabID: userPrefs.sheet_id || '', ...user }} subscription={{}}>
            {() => (
            <div className="page-content dashboard form">
                <FormAutoSave debounce={1000} save={save} />
                <div className="section">
                    <h2>Your User Account</h2>
                    <div className='setting data-item'>
                        <img className='avatar-thumbnail' src={user.picture || defaultAvatarThumbnail} alt='avatar thumbnail' />
                        <span><strong>{user.name}</strong></span>&nbsp;<span>&lt;{user.email}&gt;</span>
                    </div>
                </div>
            </div>
            )}
         </Form>
    );
/*
    return (
        <Form onSubmit={save} initialValues={{ sheetID: userPrefs.spreadsheet_id || '', tabID: userPrefs.sheet_id || '', ...user }} subscription={{}}>
            {() => (
            <div className="page-content dashboard form">
                <FormAutoSave debounce={1000} save={save} />
                <div className="section">
                    <h2>Your User Account</h2>
                    <div className='setting data-item'>
                        <img className='avatar-thumbnail' src={user.picture || defaultAvatarThumbnail} alt='avatar thumbnail' />
                        <span><strong>{user.name}</strong></span>&nbsp;<span>&lt;{user.email}&gt;</span>
                    </div>
                </div>
                <div className="section">
                    <h2>Google Sheets</h2>
                    <div className='setting data-item'>
                        <div className="description">
                            <p>Ginger can use Google Sheets to keep track of your data.</p>
                            <p>To enable this optional feature, create a Google Sheets spreadsheet document called <strong>Ginger T</strong>,
                            add a tab called <strong>Reminders</strong>, and share the spreadsheet document 
                            with <strong>ginger-t-service-account@ginger-t.iam.gserviceaccount.com</strong>  as an editor.</p>
                            <p>Once completed, find the IDs of the spreadsheet and the tab in the spreadsheet document URL 
                            and enter them in the following fields.</p>
                        </div>
                        <span className="label">Enter the ID of your Ginger T spreadsheet:</span>
                        <Field component="input" type="text" name="sheetID" placeholder="Sheet ID" />
                    </div>
                    <div className='setting data-item'>
                        <span className="label">Enter the ID of the Reminders tab:</span>
                        <Field component="input" type="text" name="tabID"   placeholder="Tab ID" />
                    </div>
                </div>
            </div>
            )}
         </Form>
    );
    */
};

export default DashboardPage;