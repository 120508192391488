import React, {useState} from 'react';
import { usePosition } from 'use-position';
import Timestamp from '../../components/Timestamp/Timestamp';
import { ProgressSpinner } from 'primereact/progressspinner';

import './LocationAnswer.css';

function getMap(latitude, longitude) {
    if (latitude && longitude) {
        return `${process.env.REACT_APP_HERE_API_MAP_VIEW_URL}?` 
            + `apiKey=${process.env.REACT_APP_HERE_API_KEY}&` 
            + `c=${latitude}%2C${longitude}&`
            + `ctr=${latitude}%2C${longitude}&` 
            + `h=420&w=420&z=12&ppi=500&poithm=0&nocp&sb=m`;
    }

    return '';
};

function GetStreetAddress(latitude, longitude) {
    const [streetAddress, setStreetAddress] = useState([]);

    if (latitude && longitude) {
        const data = fetch(`${process.env.REACT_APP_HERE_API_REV_GEOCODE_URL}?at=${latitude}%2C${longitude}&lang=en-US&apiKey=${process.env.REACT_APP_HERE_API_KEY}`)
            .then(response => response.json())
            .then(
                data => { return data['items'][0]['title']; }
            )
            .catch(
                (error) => { console.log('>>> Error in fetching street address: ', error); }
            );
        
        data.then(
            (text) => { setStreetAddress(text); }
        );
    }

    return streetAddress;
};

const LocationAnswer = (props) => {
    const {
        latitude,
        longitude,
        speed,
        timestamp,
        accuracy,
        error,
    } = usePosition(false, { enableHighAccuracy: true });

    if (latitude && longitude) {
        return(
            <div>
                <div className='location-answer'>
                    <div className='location-answer-item'>{GetStreetAddress(latitude, longitude)}</div>
                    <div className='location-answer-item'><img className='location-map' src={getMap(latitude, longitude)} alt='location-map'></img></div>                    
                </div>
                <Timestamp />
            </div>
        );
    }

    return(
        <div>
            <div className='location-placeholder'>
                <div><ProgressSpinner /></div>
                <div>
                    <span>Locating...</span>
                    <span>{GetStreetAddress(latitude, longitude)} {getMap(latitude, longitude)}</span>
                    <span>{latitude} {longitude} {speed} {timestamp} {accuracy} {error}</span>
                </div>
            </div>
            <Timestamp />
        </div>
    );
}

export default LocationAnswer;
