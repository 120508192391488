import React from 'react';
import ReactDOM from 'react-dom';
import 'primereact/resources/themes/mdc-dark-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './index.css';
import './assistant/style.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';

import Authenticated from './content/utility/Authenticated';
import LoginPage     from './content/page/LoginPage';
import HomePage      from './content/page/HomePage';
import DashboardPage from './content/page/DashboardPage';
import MissingPage   from './content/page/MissingPage';

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';

ReactDOM.render(
    (
        <React.StrictMode><BrowserRouter>
            <Routes>
                <Route path={homePage} element={<App/>} >
                    <Route path='login'     element={<LoginPage/>}/>
                    <Route path='home'      element={<HomePage/>}/>
                    <Route path='dashboard' element={<Authenticated><DashboardPage/></Authenticated>}/>
                    <Route path='*'         element={<MissingPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter></React.StrictMode>
    ),
    document.getElementById('root')
);
    
reportWebVitals();