import parse from "html-react-parser";
import { linkify } from '../../lib/utilities';

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';

const AGENTS = {
    "Answer Questions on COVID-19"           : { handle: "@covid",      config: require('../../agents/knowledge-base-covid.json') },
    "Answer Questions on Finance"            : { handle: "@finance",    config: require('../../agents/knowledge-base-finance.json') },
    "Answer Questions on General Healthcare" : { handle: "@hca",        config: require('../../agents/knowledge-base-hca.json') },
    "Answer Questions on Gov Healthcare"     : { handle: "@hcgov",      config: require('../../agents/knowledge-base-hcgov.json') },
    "Answer Questions on Student Aid"        : { handle: "@studentaid", config: require('../../agents/knowledge-base-studentaid.json') },
};

const template = (item) => {
    if (!item.answer) {
        return (
            <div>
                <div className='text'>{parse(item.message) || 'Sorry, there is no answer found for this question in the knowledge base documents'}</div>
            </div>
        );
    }

    let linkifiedAnswer = item.answer;

    if (item.answer.includes(' https://') 
        || item.answer.includes(' http://')
        || item.answer.includes('(https://')
        || item.answer.includes('(http://')
    ) {
        linkifiedAnswer = linkify(item.answer);
    }

    const parsedAnswer = parse(linkifiedAnswer);
    const byline       = <a href={item.docTitle?.url} target='_blank'>{ item.docTitle?.title }</a>;

    return (
        <div>
            <div className='title'>{ item.faqQuestion }</div>
            <div className='text' >{ parsedAnswer }<span className="divider">/</span><span className="byline">{ byline }</span></div>
        </div>
    );
};

const getSource = (props) => {
    const { agentConfig } = props;

    return {
        picture : `/${homePage}/images/avatar-agents/${agentConfig.knowledge_base.avatar}`,
        name    : agentConfig.knowledge_base.name,
        handle  : agentConfig.knowledge_base.handle,
    };
};

const getAnswer = async (props) => {
    const { agentConfig, query } = props;

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded', 'Accept': 'application/json' };
    const method  = 'POST';

    const body    = new URLSearchParams({
        'knowledgeBaseID' : agentConfig.knowledge_base.id,
        'message'         : query,
        'sessionPath'     : '',
    });

    return await fetch(process.env.REACT_APP_SEARCH_AGENT_API_TEXT_URL, { method: method, headers: headers, body: body })
        .then(response => response.json())
        .then(json => {
            const answers = json.data[0]?.queryResult.knowledgeAnswers?.answers || [];

            answers.forEach(answer => {
                const docID = answer.source.split('/')[5];
                const docTitle = agentConfig.documents[docID];
                answer['docTitle'] = docTitle;

            });
            
            return json;
        });
};

const getTitle = (props) => {
    const { answer } = props;

    switch (answer.data[0]?.queryResult.knowledgeAnswers?.answers.length) {
        case undefined: 
        case 0:  return 'No answer found';
        case 1:  return '1 answer';
        default: return `${answer.data[0]?.queryResult.knowledgeAnswers?.answers.length} answers`
    }
};

const ContentForFAQs = async (props) => {
    const { query, intentDisplayName } = props.state;

    const agentConfig = AGENTS[intentDisplayName].config;
    const source      = getSource({ agentConfig: agentConfig });
    const answer      = await getAnswer({ agentConfig: agentConfig, query: query });
    const title       = getTitle({ answer: answer });
    const message     = `Sorry, there is no answer found for <span class='query'>'${query}'</span> in this knowledge base`;

    if (title) {
        return { 
            source   : source,
            title    : title,
            template : template,
            data     : answer.data[0]?.queryResult.knowledgeAnswers?.answers || [{ message: message }]
        };
    }
};

export default ContentForFAQs;