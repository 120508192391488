import React, { useState } from 'react';
import useSWR from 'swr';

import Timestamp from '../../components/Timestamp/Timestamp';
import ClickToFlipCard from '../../components/ClickToFlipCard/ClickToFlipCard';

import './LyricsAnswer.css';

const capitalize = (string) => {
    const a = string.split(" ");

    for (let i = 0; i < a.length; i++) {
        a[i] = a[i].charAt(0).toUpperCase() + a[i].slice(1);
    }

    return a.join(" ");
};

const buildLyricsMarkup = (id, title, artist, lyrics) => {
    const lyricsMarkup = lyrics.trim().split('\n').map(
        (line, index) => {
            return (<div key={id + '-' + index}>{line}</div>);
        }
    );

    return (
        <div className='lyrics'>
            <div className='title'>{capitalize(title)}</div>
            { artist ? (<div className='artist'>{capitalize(artist)}</div>) : '' }
            <div className='text'>{lyricsMarkup}</div>
        </div>
    );
}

const fetcher = (url, method, headers, body) => fetch(url, { method: method, headers: headers, body: body }).then((response) => response.json());

const LyricsAnswer = (props) => {
    const cardInfo = { id: Date.now() };

    const [ answer, setAnswer ] = useState();

    const { data, error } = useSWR([ 
        `${process.env.REACT_APP_GET_FUN_API_BASE_URL}/lyrics`,
        'POST',
        { 'Content-Type': 'application/x-www-form-urlencoded', 'Accept': 'application/json' },
        `artist=${props.state.artist || '-'}&title=${props.state.query || 'yesterday'}`
    ], fetcher);

    if (error) {
        console.log('>>> Error: ', error);
        return "Error fetching data...";
    }
 
    if (!data && !answer) { return "Searching for song lyrics..."; }

    if (data && !answer) {
        const answerValues  = { title: '', artist: '', id: cardInfo.id, lyrics: (data.lyrics || `No lyrics found for: ${props.state.query}`) };
        answerValues.title  = props.state.parameters.fields['song-title'].stringValue || props.state.query;
        answerValues.artist = props.state.parameters.fields['artist'].listValue.values[0] ? props.state.parameters.fields['artist'].listValue.values[0].stringValue : null;

        setAnswer(answerValues);
    }

    if (!answer) { return "Searching for song lyrics..."; }

    if (answer) {
        cardInfo.frontFace = buildLyricsMarkup(answer.id, answer.title, answer.artist, answer.lyrics);
        cardInfo.backFace  = answer.title;

        return (
            <div>
                <div className="lyrics-answer"><ClickToFlipCard { ...cardInfo } /></div>
                <Timestamp />
            </div>
        );
    }
};

export default LyricsAnswer;