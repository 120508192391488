import React, {useState} from 'react';
import { Carousel } from 'primereact/carousel';

import './DealsByReddit.css';

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';
const FEED     = `/${homePage}/data/dealsapi/deals.json`;

const dealsArticleTemplate = (article) => {
    let source = 'Slickdeals';
    let byline = `Popular Deals`;
    let thumbnailURL = article.image;
    let title = article.title;

    if (thumbnailURL === 'PLACEHOLDER') thumbnailURL = `/${homePage}/images/deals-hero-image.jpg`;

    return (
        <div className='article'>
            <div className='source'>{source}</div>
            <div className='title'><a href={article.url} target="_blank" rel="noopener noreferrer">{title}</a></div>
            <div className="thumbnail"><img src={thumbnailURL} alt="" /></div>
            <div className="published-date">{new Date().toLocaleString()}</div>
            <div>
                <span className="abstract"><a href={article.url} target="_blank" rel="noopener noreferrer">Discussion thread</a></span>
                <span className='byline'>&nbsp;({article.rating})</span>
                <span className="divider">/</span>
                <span className="byline">{byline}</span>
            </div>
        </div>
    );
}

const GetContent = (props) => {
    const [content, setContent] = useState(null);

    if (!content) {
        fetch(
            props.feed, {
                headers : { 
                    'Content-Type' : 'application/json',
                    'Accept'       : 'application/json'
                }
            }
        )
        .then(response => response.json())
        .then(json     => { setContent(json); });
    }

    return content;
}

const DealsBySlickdeals = (props) => {
    const deals = GetContent({ feed: FEED });

    if (!deals) return "Getting shopping deals...";
    if (deals.popular_deals[deals.popular_deals.length - 1].title === 'END') deals.popular_deals.pop();

    return (
        <Carousel 
            className='deals'
            value={deals.popular_deals} 
            itemTemplate={dealsArticleTemplate} 
            numVisible={1} 
            numScroll={1} 
            />
    );
}

export default DealsBySlickdeals;
