import './ContentForLocationMap.css';

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';
const avatar   = `/${homePage}/images/avatar-agents/agent-map-01.png`;

const GOOGLE_API_KEY = 'AIzaSyBvXUORS6tgI08vHYpFg-JAhM1IyOJQiqQ';
const ZOOM_LEVEL     = 16;
const WIDTH          = '100%';
const HEIGHT         = 480;

const getPosition = (options) => {
    return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, options));
}

const ContentForLocationMap = async (props) => {
    const { address } = props.state.parameters.fields;

    let location = address?.stringValue;

    if (!location) {
        location = await getPosition().then(position =>
            `${position.coords.latitude},${position.coords.longitude}`
        );
    }

    const url = `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${location}&zoom=${ZOOM_LEVEL}`;

    return {
        data   : <iframe width={ WIDTH } height={ HEIGHT } src={ url } frameBorder='0' />,
        source : { picture: avatar, name: 'Map', handle: '@map' },
        title  : 'Location map'
    };
};

export default ContentForLocationMap;