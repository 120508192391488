const AssistantFeatures = () => {
    return(
        <div className="capabilities">
            <p><span className='highlight'>Ginger</span> is your personal, virtual assistant who can converse in English.</p>
            <p>Have Ginger...</p>
            <ul>
                <li>Display a <strong>clock</strong>                                                             <span className='annotation'>/ What's the time now?</span></li>
                <li>Display a countdown <strong>timer</strong>                                                   <span className='annotation'>/ I need a 90 sec timer</span></li>
                <li>Display a <strong>calendar</strong>                                                          <span className='annotation'>/ What's the date today?</span></li>
                <li>Display a <strong>calculator</strong>                                                        <span className='annotation'>/ Get me a calculator</span></li>
                <li>Find a <strong>video</strong>                                                                <span className='annotation'>/ Trailer for Dune 2021</span></li>
                <li>Find the <strong>lyrics</strong> of a song                                                   <span className='annotation'>/ Lyrics of Nevermore by Queen</span></li>
                <li>Encode a URL as <strong>QR</strong> code                                                     <span className='annotation'>/ qr https://ginger-t.link/ginger-t/</span></li>
                <li>Display current <strong>weather</strong> and 5-day <strong>forecast</strong>                 <span className='annotation'>/ Is it nice outside?</span></li>
                <li>Display today's headline <strong>news</strong>                                               <span className='annotation'>/ Tech news headlines</span></li>
                <li>Display shopping <strong>deals</strong>                                                      <span className='annotation'>/ Show me some deals</span></li>
                <li>Display connection <strong>IP address</strong>                                               <span className='annotation'>/ What's my IP address?</span></li>
                <li>Display <strong>location</strong> map                                                        <span className='annotation'>/ Map of Bangkok, Thailand</span></li>
                <li>Find <strong>elected US officials</strong>                                                   <span className='annotation'>/ Elected officials in Baltimore, MD</span></li>
                <li>Play a game of <strong>Wordle</strong>, <strong>Pac-Man</strong>, or <strong>Snake</strong>  <span className='annotation'>/ Pacman game</span></li>
                <li>Tell a <strong>joke</strong>                                                                 <span className='annotation'>/ How about a joke</span></li>
                <li>Tell a <strong>riddle</strong>                                                               <span className='annotation'>/ Tell me a riddle</span></li>
            </ul>
            <p>Or, direct more specific questions to expert advisors...</p>
            <ul>
                <li><strong>@hcgov</strong> What is a preexisting condition?</li>
                <li><strong>@hca</strong> What is a Kinesiotherapist?</li>
                <li><strong>@covid</strong> What are the symptoms?</li>
                <li><strong>@studentaid</strong> How do I get a federal student loan?</li>
                <li><strong>@finance</strong> What is a variable life insurance?</li>
            </ul>
            <p>And find the answer any questions you can think of...</p>
        </div>
    );
};

export default AssistantFeatures;