import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../lib/auth-context";
import { OAuthLogin, OAuthLogout, oAuthRefreshTokenSetup } from "../../lib/oauth-with-google";

import SITE_LINKS from "../utility/SiteLinks";

const NavigationMenu = () => {
    const { login, user, logout } = useAuth();
    const [ authLink, setAuthLink ] = useState('');

    const handleLoginSuccess = (authResult) => {
        oAuthRefreshTokenSetup(authResult);
        sessionStorage.setItem('accessToken', authResult.accessToken);
        login({ tokenId: authResult.tokenId });
    };

    const handleLoginFailure = (authResult) => {
        console.log('>>> OAuth login failed: ', authResult);
        alert(authResult);
    };

    const handleLogout = (authResult) => { sessionStorage.removeItem('accessToken'); logout(); };

    useEffect(() => {
        user?.email ?
            setAuthLink(<li><OAuthLogout onSuccess={handleLogout}/> </li>) :
            setAuthLink(<li><OAuthLogin  onSuccess={handleLoginSuccess} onFailure={handleLoginFailure}/></li>);
    }, [ user ]);

    return (
        <nav>
            <ul>
                <li><NavLink to={SITE_LINKS.home}>Home</NavLink></li>
                <li><NavLink to={SITE_LINKS.dashboard}>Dashboard</NavLink></li>
                { authLink }
            </ul>
        </nav>
    );
};

export default NavigationMenu;