import React from 'react';

import Timestamp from '../../components/Timestamp/Timestamp';

import './PlayWordleAnswer.css';

const WORDLE_APP_URL = 'https://reactle.vercel.app/';

const PlayWordleAnswer = (props) => {
    return(
        <div>
            <div className='answer wordle'><iframe src={WORDLE_APP_URL} className='wordle'/></div>
            <Timestamp />
        </div>
    );
}

export default PlayWordleAnswer;