import React from 'react';

import Timestamp from '../../components/Timestamp/Timestamp';
import FlipCard from '../../components/FlipCard/FlipCard';

import './FlipAnswer.css';

const FlipAnswer = (props) => {
    const cardInfo = {
        id:     Date.now(),
        front:  `Hello, ${props.state.utterance}!`,
        back:   "I am here!",
    };

    return (
        <div>
            <div className="flip-answer"><FlipCard { ...cardInfo } /></div>
            <Timestamp />
        </div>
    );
}

export default FlipAnswer;