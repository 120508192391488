import React from 'react';
import Timestamp from '../../components/Timestamp/Timestamp';
import Pacman from 'pacman-react';

import './PlayPacmanAnswer.css';

const PlayPacmanAnswer = (props) => {
    return(
        <div>
            <div className='playpacman-answer'>
                <Pacman gridSize={12} />
            </div>
            <Timestamp />
        </div>
    );
}

export default PlayPacmanAnswer;
