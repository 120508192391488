import { GoogleLogin, GoogleLogout } from 'react-google-login';

const clientId = process.env.REACT_APP_GINGER_T_GOOGLE_CLIENT_ID;

export function OAuthLogin(props) {
    return(
        <span className='login-button'>
            <GoogleLogin
                clientId={clientId}
                render={renderProps => (
                    <button
                        className='login-button'
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        >
                        Log In
                    </button>
                )}
                buttonText='Login'
                onSuccess={props['onSuccess']}
                onFailure={props['onFailure']}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
                scope='profile email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/tasks https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/dialogflow https://www.googleapis.com/auth/contacts'
            />
        </span>
    );
};

export function OAuthLogout(props) {
    return(
        <span className='logout-button'>
            <GoogleLogout
                clientId={clientId}
                render={renderProps => (
                    <button
                        className='logout-button'
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        >
                        Log Out
                    </button>
                )}
                buttonText='Logout'
                onLogoutSuccess={props['onSuccess']}
            />
        </span>
    );
};

export const oAuthRefreshTokenSetup = (authResult) => {
    let refreshTimer = 1000 * (authResult.tokenObj.expires_in || 3600 - 5 * 60);

    const refreshToken = async() => {
        const newAuthResult = await authResult.reloadAuthResponse().catch((err) => { console.error(err); });
        refreshTimer = 1000 * (newAuthResult.expires_in || 3600 - 5 * 60);
        setTimeout(refreshToken, refreshTimer);
    };

    setTimeout(refreshToken, refreshTimer);
};