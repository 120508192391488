import { useState } from 'react';
import QRCode from "react-qr-code";
import Timestamp from '../../components/Timestamp/Timestamp';
import ClickToFlipCard from '../../components/ClickToFlipCard/ClickToFlipCard';

import './QRAnswer.css';

const QRAnswer = (props) => {
    const [ answer, setAnswer ] = useState();

    let value = props.state.query || 'https://ginger-t.link/ginger-t/';
    
    if (value.toLowerCase().trim().startsWith('qr'))  value = value.replace('qr', '').trim();
    if (value.toLowerCase().trim().startsWith('/qr')) value = value.replace('/qr', '').trim();

    if (!answer) setAnswer({ 
        ...props.state,
        frontFace: <QRCode value={ value } level='H' title={ value } />,
        backFace: <div>{ value }</div>
    });

    if (answer) {
        return (
            <div>
                <div className="qr-answer"><ClickToFlipCard {...answer} /></div>
                <Timestamp />
            </div>
        );
    }
};

export default QRAnswer;