import React from 'react';
import Timestamp from '../../components/Timestamp/Timestamp';
import News from '../../components/NewsByNewsAPI/NewsByNewsAPIWithCarousel';
import { Card } from 'primereact/card';

import './NewsAnswer.css';

const NewsAnswer = (props) => {
    const utterance = props.state.utterance;

    let topic = "us";

    if (utterance.includes("world")) {
        topic = "world";
    }
    else if (utterance.includes("sci")) {
        topic = "science";
    }
    else if (utterance.includes("tech")) {
        topic = "technology";
    }
    else if (utterance.includes("art")) {
        topic = "entertainment";
    }
    else if (utterance.includes("health")) {
        topic = "health";
    }
    else if (utterance.includes("politics")) {
        topic = "politics";
    }
    else if (utterance.includes("travel")) {
        topic = "entertainment";
    }
    else if (utterance.includes("fashion")) {
        topic = "entertainment";
    }
    else if (utterance.includes("movie") || utterance.includes("entertain")) {
        topic = "entertainment";
    }
    else if (utterance.includes("opinion")) {
        topic = "opinion";
    }
    else if (utterance.includes("food")) {
        topic = "entertainment";
    }
    else if (utterance.includes("home")) {
        topic = "home";
    }
    else if (utterance.includes("auto") || utterance.includes("car")) {
        topic = "automobiles";
    }
    else if (utterance.includes("biz") || utterance.includes("business")) {
        topic = "business";
    }
    else if (utterance.includes("sport")) {
        topic = "sports";
    }
    else if (utterance.includes("chin")) {
        topic = "cn";
    }

    const feedTopics = [
        "business",
        "cn",
        "entertainment",
        "general",
        "health",
        "science",
        "sports",
        "technology",
        "us"
    ];

    if (!feedTopics.includes(topic)) {
        topic = "general";
    }

    props.scrollIntoView();

    return(
        <div>
            <div className='news-answer-card'><News topic={topic} /></div>
            <Timestamp />
        </div>
    );
}

export default NewsAnswer;
