import React from 'react'
import { FormSpy } from 'react-final-form'
import diff from 'object-diff'

class FormAutoSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = { values: props.values, submitting: false, myself: this };
    }

    static getDerivedStateFromProps(nextProps, prevState){
        console.log('>>> getDerivedStateFromProps prevState: ', prevState);
        console.log('>>> getDerivedStateFromProps nextProps: ', nextProps);

        if (prevState.timeout) {
            clearTimeout(prevState.timeout);
        }

        return { timeout: setTimeout(prevState.myself?.saveDiff, nextProps.debounce) };
    }
    
    saveDiff = async () => {
        if (this.promise) {
            await this.promise;
        }

        const { values, save } = this.props;

        const difference = diff(this.state.values, values);

        if (Object.keys(difference).length) {
            this.setState({ submitting: true, values });
            this.promise = save(difference, values);
            await this.promise;
            delete this.promise;
            this.setState({ submitting: false });
        }
    }
    
    render() {
        return (
            this.state.submitting && (<div className="submitting">Submitting...</div>)
        );
    }
}

export default props => (
    <FormSpy {...props} subscription={{ values: true }} component={FormAutoSave} />
);
