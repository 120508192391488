import React, { useState } from 'react';
import useSWR from 'swr';
import { decodeHtml } from '../../lib/utilities';
import Timestamp from '../../components/Timestamp/Timestamp';
import ClickToFlipCard from '../../components/ClickToFlipCard/ClickToFlipCard';

import './ClickToFlipAnswer.css';

const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';
const defaultAvatarThumbnail = `/${homePage}/images/avatar-agents/generic-knowledge-agent-book.jpg`;

const cardFront = (props) => {
    const { header, main } = props;

    return (
        <div className='ctf-card-face'>
            <div className='header row'>
                <div className='column left'  >&nbsp;</div>
                <div className='column middle'>
                    <div className='data-item'>
                        <img className='avatar-thumbnail' src={header.picture || defaultAvatarThumbnail} alt='avatar thumbnail' />
                        <span><strong>{header.name}</strong></span>&nbsp;/&nbsp;<span>{header.email}</span>
                    </div>
                </div>
                <div className='column right' >&nbsp;</div>
            </div>
            <div className='content'>{main}</div>
        </div>
    );
}

const cardBack = () => {
    return 'Back';
}

const fetcher = (url) => fetch(url).then((response) => response.json());

const ClickToFlipAnswer = (props) => {
    const header = {
        picture: '',
        name: 'Government Healthcare Advisor',
        email: '@hcgov'
    };

    const main = 'Content';

    const cardInfo = { id: Date.now() };
    const cardContent = { front: cardFront({header: header, main: main}), back: cardBack()};

    if (cardContent) {
        cardInfo.frontFace = cardContent.front;
        cardInfo.backFace  = cardContent.back;

        return (
            <div>
                <div className="clicktoflip-answer with-header"><ClickToFlipCard { ...cardInfo } /></div>
                <Timestamp />
            </div>
        );
    }
};

export default ClickToFlipAnswer;