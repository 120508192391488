const homePage = process.env.REACT_APP_GINGER_T_HOME_PAGE || '';

const AvatarIcons = {
    assistantIcons: [
        `/${homePage}/images/avatar-assistant/ginger-tea-512.png`,
        `/${homePage}/images/avatar-assistant/female-support-512.png`,        
        `/${homePage}/images/avatar-assistant/male-support-512.png`,
    ],
    userIcons: [
        `/${homePage}/images/avatar-system/generic-oval-512.png`,
        `/${homePage}/images/avatar-system/generic-round-512.png`,
        `/${homePage}/images/avatar-user/boy-512.png`,
        `/${homePage}/images/avatar-user/girl-512.png`,
        `/${homePage}/images/avatar-user/male-512.png`,
        `/${homePage}/images/avatar-user/business-male-512.png`,
        `/${homePage}/images/avatar-user/older-male-512.png`,
        `/${homePage}/images/avatar-user/old-female-512.png`,
        `/${homePage}/images/avatar-user/old-male-512.png`,
    ],
    systemIcons: [
        `/${homePage}/images/avatar-system/generic-oval-512.png`,
        `/${homePage}/images/avatar-system/generic-round-512.png`,
    ],
    agentIcons: [
        `/${homePage}/images/avatar-agents/generic-knowledge-agent.png`,
    ],
};

export default AvatarIcons;