import { useEffect, useState } from "react";
import { useAuth } from "../../lib/auth-context";

import AssistantFeatures from '../../assistant/AssistantFeatures';
import AssistantLauncher from "../../assistant/AssistantLauncher";

const generic = () => (
    <div className="page-content">
        <p className='user-greetings'>Welcome, guest!</p>
        <p className='user-greetings'>You may continue as a guest user or log in to personalize your experience.</p>
        <AssistantFeatures/>
        <AssistantLauncher/>
    </div>
);

const personalized = (user) => (
    <div className="page-content">
        <p className='user-greetings'>Welcome, {user.name}!</p>
        <p className='user-greetings'>You are logged in as {user.email}</p>
        <AssistantFeatures/>
        <AssistantLauncher user={user}/>
    </div>
);

const HomePage = () => {
    const { user } = useAuth();
    const [ content, setContent ] = useState(generic);

    useEffect(() => {
        user?.email ?
            setContent(personalized(user)) :
            setContent(generic());
    }, [ user ]);

    return (content);
};

export default HomePage;