import { useState } from 'react';
import useSWR from 'swr';
import { Carousel } from 'primereact/carousel';
import YouTube from 'react-youtube';

import './VideoByYouTube.css';

const SAFE_SEARCH_COOKIE = "PREF=f2=8000000";
const VIDEO_COUNT_LIMIT  = 20;

const fetcher = (url) => fetch(url, { headers: { cookie: SAFE_SEARCH_COOKIE }})
    .then((response) => response.text())
    .then((html) => {
        let details = [];
        let fetched = false;

        try {
            let data = html.split("ytInitialData = JSON.parse('")[1].split("');</script>")[0];

            html = data.replace(/\\x([0-9A-F]{2})/gi, (...items) => {
                return String.fromCharCode(parseInt(items[1], 16));
            });
        } catch {};

        try {
            details = JSON.parse(html.split('{"itemSectionRenderer":{"contents":')[html.split('{"itemSectionRenderer":{"contents":').length - 1].split(',"continuations":[{')[0]);
            fetched = true;
        } catch {};

        if (!fetched) {
            try {
                details = JSON.parse(html.split('{"itemSectionRenderer":')[html.split('{"itemSectionRenderer":').length - 1].split('},{"continuationItemRenderer":{')[0]).contents;
                fetched = true;
            } catch {}
        }

        if (!fetched) return [];

        const videos = [];

        details.reduce(
            (previous, current) => {
                if (current.videoRenderer) {
                    const viewCount = (current.videoRenderer.viewCountText && current.videoRenderer.viewCountText.simpleText) ? 
                        current.videoRenderer.viewCountText.simpleText.split(' ')[0].replaceAll(',', '').trim() : '0';

                    videos.push({ 
                        id:     current.videoRenderer.videoId, 
                        title:  current.videoRenderer.title.runs[0].text, 
                        byline: current.videoRenderer.longBylineText.runs[0].text,
                        views:  Number(viewCount)
                    });
                    return videos;
                }
            }, videos
        );

        return videos.sort((a, b) => Number(b.views) - Number(a.views)).splice(0, VIDEO_COUNT_LIMIT);
    });

const videoArticleTemplate = (article) => {
    return (
        <div className='article'>
            <div className='source'>{article.byline}</div>
            <div className='title'>{article.title}</div>
            <YouTube className='youtube-video' videoId={article.id} opts={{ width: '100%' }} />
        </div>
    );
};

const VideoByYouTube = (props) => {
    const searchURL = `${process.env.REACT_APP_CORS_PROXY_URL}https://youtube.com/results?q=${encodeURIComponent(props.query)}`;

    const [ videos, setVideos ] = useState();
    const { data, error } = useSWR(searchURL, fetcher);
 
    if (error) {
        console.log('>>> Error: ', error);
        return "Error fetching videos...";
    }
 
    if (!data && !videos) return "Loading videos...";
    if (data && !videos) setVideos(data);
    if (!videos) return "Loading videos...";

    return (
        <Carousel 
            className='video'
            value={videos}
            itemTemplate={videoArticleTemplate} 
            numVisible={1} 
            numScroll={1} 
            />
    );
};

export default VideoByYouTube;