import React, {useState} from 'react';

import './Timestamp.css';

const Timestamp = () => {
    const [timestamp, setTimestamp] = useState();

    if (!timestamp) {
        const date = new Date();

        setTimestamp({
            date: date, timestamp: date.toLocaleDateString() + ' at ' + date.toLocaleTimeString()
        });
    }

    if (timestamp) {
        return <div className='timestamp'>{timestamp.timestamp}</div>;
    }
};

export default Timestamp;