import React, { useState } from 'react';

import ClickToFlipCard       from '../../components/ClickToFlipCard/ClickToFlipCard';
import Timestamp             from '../../components/Timestamp/Timestamp';
import ContentForLocationMap from './ContentForLocationMap';

import '../CarouselAnswer/CarouselAnswer.css';

const defaultAvatarThumbnail = '';

const contentProviders = {
    'Display Map' : ContentForLocationMap,
    'display map' : ContentForLocationMap,
};

const cardFront = (props) => {
    const { content } = props;
    const handleClick = (e) => { e.stopPropagation(); };

    return (
        <div className='ctf-card-face front'>
            <div className='header row'>
                <div className='logo'>
                    <img className='avatar-thumbnail' src={content.source.picture || defaultAvatarThumbnail} alt='avatar thumbnail' />
                </div>
                <div className='text'>
                    <div className='title first'>
                        <span className='handle'><strong>{content.source.name}</strong></span>&nbsp;/&nbsp;<span>{content.source.handle}</span>
                    </div>
                    <div className='title second'>
                        <span className='content-title'>{content.title}</span>
                    </div>
                </div>
            </div>
            <div className='content' onClick={e => handleClick(e)}>
                {content.data}
            </div>
        </div>
    );
};

const cardBack = (props) => {
    const { content } = props;

    return (
        <div className='answer-source'>
            <img className='avatar-thumbnail' src={ content.source.picture } alt='agent thumbnail' />
            <div className='knowledge-base'>{ content.source.name }</div>
            <div className='handle'>{ content.source.handle }</div>
        </div>
    );
};

const CardAnswer = (props) => {
    const [ cardInfo,        setCardInfo        ] = useState(null);
    const [ contentProvider, setContentProvider ] = useState(null);

    if (!contentProvider) {
        if (contentProviders[props.state.intentDisplayName]) {
            contentProviders[props.state.intentDisplayName]({ ...props }).then(provider => setContentProvider(provider));
        }
        else if (contentProviders[props.state.query]) {
            contentProviders[props.state.query]({ ...props }).then(provider => setContentProvider(provider));
        }
        else {
            setContentProvider({});
        }
    }

    if (!contentProvider) return ('Fetching content provider...');

    if (!cardInfo) {
        if (contentProvider.data) {
            setCardInfo({
                id        : Date.now(),
                frontFace : cardFront({ content: contentProvider }),
                backFace  : cardBack({ content: contentProvider })
            });
        }
        else {
            setCardInfo({
                id        : Date.now(),
                frontFace : props.state.userMessage.input,
                backFace  : props.state.handler
            });
        }
    }

    if (!cardInfo) return ('Looking for an answer...');

    return (
        <div>
            <div className="carousel-card clicktoflip-answer with-header"><ClickToFlipCard { ...cardInfo } /></div>
            <Timestamp />
        </div>
    );
};

export default CardAnswer;